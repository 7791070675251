import * as React from 'react'
import * as ReactDOM from 'react-dom'
interface Props {
    class?: string
    width?: string | number
}
interface State {
    marginLeft: number
}
export class FitInScreenBody extends React.Component<Props, State> {
    state = {
        marginLeft: 0,
    }
    componentDidMount() {
        const rect = (ReactDOM.findDOMNode(this) as Element).getBoundingClientRect() as DOMRect
        const w = window.innerWidth
        let marginLeft = 0
        if (w - 30 < rect.x + rect.width) {
            marginLeft = Math.min(rect.x, rect.x + rect.width - w + 30)
        }
        this.setState({ marginLeft })
    }
    render() {
        let style = { marginLeft: -this.state.marginLeft, width: this.props.width }
        return (
            <div className={this.props.class ? this.props.class : 'data-table-filter-body'} style={style}>
                {this.props.children}
            </div>
        )
    }
}
