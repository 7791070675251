import * as React from 'react'
interface Props {
    children?: any
    disabled?: boolean
    onClick?: (e: any) => void
}
export const StickySaveButtonTabs = (props: Props) => (
    <button
        onClick={props.onClick}
        disabled={props.disabled}
        type="submit"
        className="button has-glow save fixed in-tab"
    >
        {props.children}
    </button>
)
export const StickySaveButton = (props: Props) => (
    <button onClick={props.onClick} disabled={props.disabled} type="submit" className="button has-glow save fixed">
        {props.children}
    </button>
)
export const StaticSaveButton = (props: Props) => (
    <button onClick={props.onClick} disabled={props.disabled} type="submit" className="button has-glow save">
        {props.children}
    </button>
)
export const ButtonSmallGreen = (props: Props) => (
    <i
        onClick={props.disabled ? undefined : props.onClick}
        className={`button is-small is-green has-glow ${props.disabled ? 'disabled' : ''}`}
    >
        {props.children}
    </i>
)
export const ButtonSmallOrange = (props: Props) => (
    <i
        onClick={props.disabled ? undefined : props.onClick}
        className={`button button--view-tour is-orange is-small has-glow ${props.disabled ? 'disabled' : ''}`}
    >
        View Tour
    </i>
)
