import * as React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useForm } from 'react-hook-form'
import * as MediaStore from '../store/Media'
import { ApplicationState } from '../store'
import { nameof } from '../services/nameOf'
import { FitInScreenBody } from '../FitInScreenBody'
import { TextAreaField } from '../Form/Fields/TextAreaField'
import { TextFormField } from '../Form/Fields/TextFormField'
import { Photo } from '../models/Photo'
interface Props {
    photo: Photo
}
const MediaEditPopup = (props: Props) => {
    const dispatch = useDispatch()
    const { register, handleSubmit } = useForm()
    const photo = useSelector((state: ApplicationState) => state.photos.edit)
    const locale = useSelector((state: ApplicationState) => state.localization.locale)
    const onSubmit = (data: any) => {
        dispatch(MediaStore.actionCreators.update(data))
    }
    const onCancel = () => {
        dispatch(MediaStore.actionCreators.edit(undefined))
    }
    return photo?.id == props.photo.id ? (
        <FitInScreenBody width={400} class={'data-table-filter-body-media-edit'}>
            <div className="edit-uploaded-item-popup is-shown">
                <form
                    onSubmit={(e) => {
                        e.preventDefault()
                        handleSubmit(onSubmit)
                        e.stopPropagation()
                    }}
                >
                    <a onClick={onCancel} className="edit-popup-close"></a>
                    <h4>{locale.getString('Edit Photo')}</h4>
                    <TextFormField
                        register={register}
                        defaultValue={photo.title}
                        label={locale.getString('Title')}
                        name={nameof<Photo>('title')}
                    />
                    <TextAreaField
                        register={register}
                        defaultValue={photo.description}
                        label={locale.getString('Description')}
                        name={nameof<Photo>('description')}
                    />

                    <div className="edit-popup-footer">
                        <a onClick={onCancel} className="edit-popup--cancel">
                            {locale.getString('Cancel')}
                        </a>
                        <button type="submit" className="edit-popup--save button is-orange">
                            {locale.getString('Save')}
                        </button>
                    </div>
                </form>
            </div>
        </FitInScreenBody>
    ) : null
}
export default MediaEditPopup
