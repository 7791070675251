export class Photo {
    public static Create(obj: Photo) {
        return Object.assign(new Photo(), obj)
    }
    id: number = 0
    title: string = ''
    description: string = ''
    urlBig: string = ''
    urlMedium: string = ''
    urlSmall: string = ''
}
