export class PaginationModel {
    public static Create(obj: PaginationModel) {
        return Object.assign(new PaginationModel(), obj)
    }
    currentPage: number = 1
    totalItems: number = 1
    itemsPerPage: number = 1
    prevPage: number = 1
    nextPage: number = 1
    totalPages: number = 1
    from: number = 1
    to: number = 1
    pagesArray() {
        let res = [] as number[]
        const tp = this.totalPages
        for (let i = 1; i <= tp; i++) {
            res.push(i)
        }
        if (tp > 10) {
            if (this.currentPage > 4 && this.currentPage < tp - 3) {
                res = res
                    .slice(0, 2)
                    .concat([-1])
                    .concat(res.slice(this.currentPage - 2, this.currentPage - 2 + 3))
                    .concat([-1])
                    .concat(tp - 2, tp)
            } else {
                if (this.currentPage <= 4) {
                    res = res
                        .slice(0, 5)
                        .concat([-1])
                        .concat(res.slice(tp - 2, tp))
                } else {
                    if (this.currentPage >= tp - 3) {
                        res = res
                            .slice(0, 2)
                            .concat([-1])
                            .concat(res.slice(tp - 5, tp))
                    }
                }
            }
        }
        return res
    }
}


