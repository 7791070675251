export class Language {
    public static Create(obj: Language) {
        return Object.assign(new Language(), obj)
    }
    id: number = 0
    name: string = ''
    code: string = ''
    isOffers: boolean = false
    isDeleted: boolean = false
    imgUrl: string = ''
}
