import * as React from 'react'
interface Props {
    title: string
    children: any
}
export const HeaderMenuItemWithChildren = (props: Props) => {
    const [isOpened, setIsOpened] = React.useState(false)
    const toggle = () => {
        setIsOpened(!isOpened)
    }
    return (
        <li onClick={toggle} className="has-children">
            <a>{props.title}</a>
            <ul className={`sub-menu${isOpened ? ' mobile-opened' : ''}`}>{props.children}</ul>
        </li>
    )
}
