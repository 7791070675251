import moment from 'moment'

// export const FilterType = {
//     NONE: 1,
//     TEXT: 2,
//     TEXTNUMBER: 3,
//     SELECT: 4,
//     MULTISELECT: 5,
//     DATERANGE: 6,
//     YEARSELECTOR: 7,
//     DATEPICKER: 8,
// }
export enum FilterType {
    NONE,
    TEXT,
    TEXTNUMBER,
    SELECT,
    MULTISELECT,
    DATERANGE,
    YEARSELECTOR,
    DATEPICKER,
    DATERANGEOPTIONAL,
    MULTISELECTNUMBER
}
export class FilterDateRangeType {
    static Create = (startDate: moment.Moment, endDate: moment.Moment) => {
        return Object.assign(new FilterDateRangeType(), { startDate, endDate })
    }
    constructor() {
        this.startDate = moment()
        this.endDate = moment()
    }
    startDate: moment.Moment
    endDate: moment.Moment
    toString() {
        let res = ''
        if (this.startDate) {
            res += `From: ${this.startDate.format('DD.MM.YYYY')} `
        }
        if (this.endDate) {
            res += `To: ${this.endDate.format('DD.MM.YYYY')}`
        }
        return res
    }
    get isActive() {
        return typeof this.startDate != 'undefined' || typeof this.endDate != 'undefined'
    }
}
export type FilterValueType =
    | {
        value: undefined | string | number | number[] | string[] | FilterDateRangeType | moment.Moment
        type: FilterType
    }
    | undefined

export class FilterValuesWrapper {
    filter: Map<string, FilterValueType>
    constructor(filter: Map<string, FilterValueType>) {
        this.filter = filter
    }
    get query(): string {
        return Array.from(this.filter).reduce((res: string, v) => {
            return `${res}${v[0]}-${serializeValue(v[1])}~`
        }, '')
    }
}
const serializeValue = (filterValue: FilterValueType) => {
    if (!filterValue) {
        return ''
    }
    const value = filterValue.value || ''
    const valuetype = typeof value
    switch (filterValue.type) {
        case FilterType.DATERANGE:
            const dates = value as FilterDateRangeType
            if (dates && dates.startDate && dates.endDate) {
                return `${dates.startDate.format('DD.MM.YYYY')}to${dates.endDate.format('DD.MM.YYYY')}`
            } else {
                if (dates && (dates.startDate || dates.endDate)) {
                    return `${(dates.startDate || dates.endDate).format('DD.MM.YYYY')}`
                }
                return ''
            }
        case FilterType.TEXT:
        case FilterType.TEXTNUMBER:
            return (value as string).replace('_', '%').replace('-', '*')
        case FilterType.MULTISELECT:
            return (value as string[])
                .map((x) => {
                    if (typeof x == 'string') {
                        return x.replace('-', '!')
                    } else {
                        return x
                    }
                })
                .join('_')
        case FilterType.MULTISELECTNUMBER:
            return value
    }
    return ''
}
