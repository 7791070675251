import * as React from 'react'
import './styles.scss'
import 'pure-react-carousel/dist/react-carousel.es.css';
import ReactTooltip from 'react-tooltip';
import { round } from '../Utils/round';


type Props = {
  label: string;
  tooltip?: string;
  price?: number;
  delPrice?: number;
  disabled?: boolean
  count?: number;
  minValue?: number;
  hidePrice?: boolean
  onChange: (value: number) => void;
};
export const NumPeopleInput = ({ label, tooltip, price, delPrice, minValue, count = 0, hidePrice, onChange, disabled }: Props) => {
  const counterChangeHandler = (newValue: number) => {
    if (typeof (minValue) == 'undefined' || newValue >= minValue) onChange(newValue);
  };
  const priceBlock = (price && !hidePrice) ? <React.Fragment>AED {price} {delPrice && <del>AED {delPrice}</del>}</React.Fragment> : null
  const totalPriceBlock = hidePrice ? <div className="number-input-price"></div> : <div className="number-input-price"> {price ? `${round(count * (price || 0))?.toFixed(2)} AED` : ''}</div>
  return (
    <div className="number-input-container">
      <div className="number-input-label">
        <h6>
          {label}
          <span data-tip data-for={label}>
            i{' '}
          </span>
          <ReactTooltip id={label} place="top" type="info" effect="float" multiline={true}>
            {tooltip}
          </ReactTooltip>
        </h6>
        {priceBlock}
      </div>
      <div className="counter-container">
        <button
          type="button"
          disabled={disabled}
          className="counter-container__button counter-container__button_left"
          onClick={counterChangeHandler.bind(undefined, count - 1)}
        >
          -
        </button>
        <input disabled={disabled} type="number" className="counter-container__input" value={count} />
        <button
          type="button"
          disabled={disabled}
          className="counter-container__button counter-container__button_right"
          onClick={counterChangeHandler.bind(undefined, count + 1)}
        >
          +
        </button>
      </div>
      {totalPriceBlock}
    </div>
  );
};

