import * as React from 'react'
import { BaseFormFieldProps, FormControllerProps } from '../BaseProps'
import { FormFieldWrapper } from '../Fields/FormFieldWrapper'
import { Controller } from 'react-hook-form'
type ToggleButtonFormFieldProps = BaseFormFieldProps & {
    yesText: string | React.ReactNode
    noText: string | React.ReactNode
    customClass?: string
    customWrapperClass?: string
}
export const ToggleButtonsSmallFieldController = (props: ToggleButtonFormFieldProps & FormControllerProps) => (
    <Controller
        rules={props.rules}
        control={props.control}
        name={props.name || ''}
        // defaultValue={props.defaultValue}
        render={({ field }) => (
            <ToggleButtonsFormField
                {...props}
                onChange={(e: any) => {
                    if (props.onChange) {
                        props.onChange(e)
                    }
                    field.onChange(e)
                    return e
                }}
                value={field.value}
                customClass="small"
                customWrapperClass="right"
            />
        )}
    />
)
export const ToggleButtonsFieldController = (props: ToggleButtonFormFieldProps & FormControllerProps) => (
    <Controller
        rules={props.rules}
        control={props.control}
        name={props.name || ''}
        defaultValue={props.defaultValue}
        render={({ field }) => (
            <ToggleButtonsFormField
                {...props}
                onChange={(e: any) => {
                    if (props.onChange) {
                        props.onChange(e)
                    }
                    field.onChange(e)
                    return e
                }}
                value={field.value}
            />
        )}
    />
)
export const ToggleButtonsFormField = (props: ToggleButtonFormFieldProps) => {
    const [value, setValue] = React.useState(props.defaultValue)
    React.useEffect(() => {
        setValue(props.value || props.defaultValue)
    }, [props.value, props.defaultValue])
    const onYes = () => {
        if (!props.disabled) {
            if (props.onChange) props.onChange(true)
            setValue(true)
        }
    }
    const onNo = () => {
        if (!props.disabled) {
            if (props.onChange) props.onChange(false)
            setValue(false)
        }
    }
    return (
        <FormFieldWrapper {...props}>
            <div
                className={`custom-radio-wrap is-switch-style ${props.customClass || ''}${
                    props.disabled ? ' disabled' : ''
                }${value ? ' is-checked' : ''}`}
            >
                <input
                    type="radio"
                    // {...props.register(props.name)}
                    id={`${props.name}-yes`}
                    tabIndex={props.tabIndex}
                    name={props.name}
                    value={1}
                    checked={value}
                    onChange={onYes}
                    className="custom-switch"
                    data-label={props.yesText}
                />
                <span onClick={onYes}>{props.yesText}</span>
            </div>
            <div
                className={`custom-radio-wrap is-switch-style${props.disabled ? ' disabled' : ''} ${props.customClass}${
                    !value ? ' is-checked' : ''
                }`}
            >
                <input
                    type="radio"
                    // ref={props.innerRef}
                    // {...props.register(props.name)}
                    id={`${props.name}-no`}
                    name={props.name}
                    value={0}
                    onChange={onNo}
                    className="custom-switch"
                    checked={!value}
                    data-label={props.noText}
                />
                <span onClick={onNo}>{props.noText}</span>
            </div>
        </FormFieldWrapper>
    )
}
