import { Reducer, Action } from 'redux'
import * as React from 'react'
import { AppThunkAction } from '.'
import { Language } from '../models/Language'
import ListCache from '../serviceModels/LocalCachedData'
import { apiCallWrapper } from '../services/apiCallWrapper'
import * as LoadingStore from './Loading'
import { DefaultOptionsSortFunc, SelectOptionModel } from '../Select/SelectOptionModel'
import { syncDataLists } from '../Utils/syncDataLists'

export interface State {
    isLoading: boolean
    languages: Language[]
    languagesHash: Map<number, Language>
    languagesOptions: SelectOptionModel[]
}
const REQUEST_SHARED_LANGUAGES = 'REQUEST_SHARED_LANGUAGES'
interface RequestSharedLanguages {
    type: 'REQUEST_SHARED_LANGUAGES'
}
const RECIEVE_SHARED_LANGUAGES = 'RECIEVE_SHARED_LANGUAGES'
interface ReceiveSharedLanguages {
    type: 'RECIEVE_SHARED_LANGUAGES'
    languages: Language[]
}
type KnownAction = RequestSharedLanguages | ReceiveSharedLanguages
type LanguagesListResult = {
    date: string
    languages: Language[]
}
export const actionCreators = {
    save: (model: Language): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
        let response = await apiCallWrapper<Language>(`/api/languages`, 'POST', dispatch, model)
        const payload = response.Content
        if (response.IsOk && payload) {
            const languages = getState().languages.languages
            const newLanguages = languages.some((x: Language) => x.id == payload.id)
                ? languages.map((x: Language) => (x.id == payload.id ? payload : x))
                : [payload].concat(languages)
            dispatch({ type: RECIEVE_SHARED_LANGUAGES, languages: newLanguages })
        }
    },
    requestLanguages: (isOffers: boolean = true): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (
        dispatch,
        getState,
    ) => {
        const langsLocal = window.localStorage.getItem('languages')
        let languages: Language[] = []
        let syncDate: string | undefined = undefined
        if (langsLocal) {
            const cache = Object.assign(new ListCache<Language>(), JSON.parse(langsLocal)) as ListCache<Language>
            languages = cache.items
            syncDate = cache.date
        }
        let fetchTask = await apiCallWrapper<LanguagesListResult>(
            `/api/languages/sync?date=${syncDate || ''}&isOffers=${isOffers}`,
            'GET',
            dispatch,
            null,
        )
        const data = fetchTask.Content
        if (fetchTask.IsOk && data) {
            languages = syncDataLists(languages, data.languages) as Language[]
            window.localStorage.setItem('languages', JSON.stringify(new ListCache<Language>(data.date, languages)))
            dispatch({ type: RECIEVE_SHARED_LANGUAGES, languages })
        }
    },
}
const unloadedState: State = {
    isLoading: false,
    languages: [],
    languagesOptions: [],
    languagesHash: new Map<number, Language>(),
}
const customRenderer = (langs: Map<number, Language>) => (option: SelectOptionModel) => {
    const l = langs.get(option.value as number)
    const code = l ? l.code : 'en'
    return (
        <>
            <i className={`header-lang header-lang--${code}`}></i>
            {option.label}
        </>
    )
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case REQUEST_SHARED_LANGUAGES:
            return state
        case RECIEVE_SHARED_LANGUAGES:
            const languagesHash = action.languages.reduce((res, next) => {
                res.set(next.id, next)
                return res
            }, new Map<number, Language>())
            return {
                ...state,
                isLoading: false,
                languagesHash,
                languagesOptions: action.languages
                    .map((x) => ({
                        value: x.id,
                        label: x.name,
                        customRenderer: customRenderer(languagesHash),
                    }))
                    .sort(DefaultOptionsSortFunc),
                languages: action.languages.map((x) => Language.Create(x)),
            }
        default:
            const exhaustiveCheck: never = action
    }
    return state || unloadedState
}
