import * as React from 'react'
import moment from 'moment'
import FilterIcon from './FilterIcon'
import { FilterBody } from './FilterBody'
import DayPicker from 'react-day-picker/DayPicker'
import { useOnCloseElement } from '../../Effects/useOnCloseElement'

interface DateFilterProps {
    value?: moment.Moment
    onClose?: () => void
    children?: React.ReactNode
    externalControlled?: boolean
    onSubmit: (value?: moment.Moment) => void
}
interface DateFilterState {
    isOpen: boolean
    value?: moment.Moment
}
export const DateFilter = (props: DateFilterProps) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [value, setValue] = React.useState(props.value)

    const close = () => {
        setIsOpen(false)
        setValue(props.value)
        if (props.onClose) {
            props.onClose()
        }
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    const handleClickOutside = () => {
        close()
    }
    const onSubmit = () => {
        props.onSubmit(value)
        close()
    }
    const { externalControlled } = props
    return (
        <div ref={ref as any} className={externalControlled ? '' : 'data-table-filter'}>
            {!externalControlled && (
                <FilterIcon
                    title={props.value ? props.value.format('DD.MM.YYYY') : undefined}
                    isActive={props.value ? true : false}
                    isOpen={isOpen}
                    onClick={() => {
                        setIsOpen(!isOpen)
                        setValue(props.value)
                    }}
                />
            )}
            {(isOpen || externalControlled) && (
                <FilterBody onClose={close} width={275}>
                    <DayPicker
                        selectedDays={value ? value.toDate() : undefined}
                        onDayClick={(e: any) => setValue(moment.utc(e).startOf('day'))}
                    />
                    {props.children}
                    <button onClick={onSubmit} className="data-table-filter-add-button">
                        Submit
                    </button>
                    <div className="clear"></div>
                </FilterBody>
            )}
        </div>
    )
}
