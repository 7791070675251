import { Action, Reducer } from 'redux'
import * as LoadingStore from './Loading'
import { AppThunkAction } from '.'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export interface Translation {
    text: string
    value: string
}
export class Localization {
    public async setLanguage(lang: string, dispatch: any) {
        if (lang != this.lang) {
            // const locale = await import(`./locales/${lang}.ts`)
            // this.language = locale.default
        }
    }
    public static FromServerString(value: string) {
        if (value && value.length) {
            const translations = JSON.parse(value)
            if (translations.length) {
                return Object.assign(new Localization(), {
                    language: (translations as Translation[]).reduce((res, next) => {
                        res.set(next.text, next.value)
                        return res
                    }, new Map<string, string>()),
                })
            }
        }
        return new Localization()
    }
    public getString(key: string) {
        return this.language.get(key) || key
    }
    private lang: string = 'en'
    public language: Map<string, string> = new Map<string, string>()
}

export interface State {
    locale: Localization
    language: string
}
export const SET_GLOBAL_LANGUAGE = 'A_SET_GLOBAL_LANGUAGE'
export interface SetGlobalLanguageAction {
    type: 'A_SET_GLOBAL_LANGUAGE'
    language: string
    locale: Localization
}
export const actionCreators = {
    initialize: (): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch, getState) => {
        const language = getState().global.language
        const locale = new Localization()
        await locale.setLanguage(language, dispatch)
        dispatch({ type: SET_GLOBAL_LANGUAGE, language, locale } as SetGlobalLanguageAction)
    },
    setLanguage: (language: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const locale = new Localization()
        dispatch(LoadingStore.actionCreators.incrementLoading() as any)
        await locale.setLanguage(language, dispatch)
        dispatch(LoadingStore.actionCreators.decrementLoading() as any)

        dispatch({ type: SET_GLOBAL_LANGUAGE, language, locale } as SetGlobalLanguageAction)
    },
}
type KnownAction = SetGlobalLanguageAction

const storageState = typeof localStorage != 'undefined' && localStorage ? localStorage.getItem('language') : undefined
const unloadedState: State = storageState
    ? Object.assign(JSON.parse(storageState), { locale: new Localization() })
    : { language: 'en', locale: new Localization() }
const updateStorage = (state: State) => {
    if (typeof localStorage != 'undefined' && localStorage) {
        localStorage.setItem('language', JSON.stringify(state))
    }
}
export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }

    const action = incomingAction as KnownAction
    switch (action.type) {
        case SET_GLOBAL_LANGUAGE:
            state = {
                ...state,
                language: action.language,
                locale: action.locale,
            }
            updateStorage(state)
            return state

        default:
            return state
    }
}
