import * as React from 'react'
import { toStringFormatWithNumDigits } from './Utils/toStringFormatWithNumDigits'
export interface TimePickerProps {
    value?: string
    defaultValue?: string
    className?: string
    removeDefaultClass?: boolean
    style?: React.CSSProperties
    id?: string
    onBlur?: (e: any) => void
    onChange: (val: string) => void
}
export const minutesToTimeString = (val: number) => {
    const h = Math.trunc(val / 60)
    const m = val % 60
    return `${toStringFormatWithNumDigits(h)}:${toStringFormatWithNumDigits(m)}`
}
export const timeStringToMinutes = (val: string) => {
    try {
        const splt = val.split(':')
        return parseInt(splt[0]) * 60 + parseInt(splt[1])
    } catch {
        return 0
    }
}
export const formatToTime = (val: any, initVal: any, cursorPosition: number) => {
    let IsWrongVal = false
    if (val.length > 5) {
        val = val.slice(0, cursorPosition) + val.slice(cursorPosition + 1)
        val = val.slice(0, 5)
    }
    if (val.length == 0) {
        val = '00:00'
    } else {
        if (val.length == 1) {
            val += '0:00'
        } else {
            if (val.length == 2) {
                val += ':00'
            } else {
                if (val.length == 3) {
                    val += '00'
                } else {
                    if (val.length == 4) {
                        val = val.slice(0, cursorPosition) + '0' + val.slice(cursorPosition)
                    }
                }
            }
        }
    }
    if (val.length > 0 && isNaN(parseInt(val[0]))) {
        IsWrongVal = true
    } else {
        let v = parseInt(val[0])
        if (v > 2) {
            IsWrongVal = true
        }
    }
    if (val.length > 1 && isNaN(parseInt(val[1]))) {
        IsWrongVal = true
    } else {
        let v = parseInt(val[1])
        let v1 = parseInt(val[0])
        var _v = v1 * 10 + v
        if (_v > 23) {
            IsWrongVal = true
        }
    }
    if (val.length > 2 && isNaN(parseInt(val[2])) && val[2] !== ':') {
        IsWrongVal = true
    } else {
        if (val.length > 2 && val[2] !== ':') {
            val = val.slice(0, 2) + ':' + val.slice(2)
        }
    }
    if (val.length > 3 && isNaN(parseInt(val[3]))) {
        IsWrongVal = true
    } else {
        let v = parseInt(val[3])
        if (v > 5) {
            IsWrongVal = true
        }
    }
    if (val.length > 4 && isNaN(parseInt(val[4]))) {
        IsWrongVal = true
    } else {
        let v = parseInt(val[4])
        if (v > 9) {
            IsWrongVal = true
        }
    }
    val = val.slice(0, 5)
    if (IsWrongVal) {
        val = initVal
    } else {
    }
    return { val, IsWrongVal }
}
export const TimePicker = (props: TimePickerProps) => {
    const [cursorPos, setCursorPos] = React.useState(0)
    const [prevCursorPosition, setPrevCursorPosition] = React.useState(0)
    const [value, setValue] = React.useState('00:00')
    React.useEffect(() => {
        const newValue = props.value || props.defaultValue || '00:00'
        if (newValue != value) setValue(newValue)
    }, [props.value, props.defaultValue])
    const ref = React.useRef()

    React.useEffect(() => {
        if (ref && ref.current) {
            ;(ref.current as any).setSelectionRange(cursorPos, cursorPos)
        }
    })

    const onBlur = (e: any) => {
        if (props.onBlur) {
            props.onBlur(e)
        }
    }
    const onChange = (e: any) => {
        let val = e.target.value
        let initVal = value
        let cursorPos = e.target.selectionStart
        let prevCursorPos = cursorPos
        let res = formatToTime(val, initVal, cursorPos)
        val = res.val
        if (res.IsWrongVal) {
            cursorPos--
        }
        if (cursorPos === 3) {
            if (prevCursorPos > 3) {
                cursorPos--
            } else {
                cursorPos++
            }
        }
        setCursorPos(cursorPos)
        setValue(val)
        props.onChange(val)
        e.target.selectionStart = cursorPos
    }
    return (
        <input
            type="text"
            id={props.id}
            ref={ref as any}
            className={
                (props.removeDefaultClass ? '' : 'booking-table-time-picker') +
                (props.className ? ` ${props.className}` : '')
            }
            style={props.style}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
        />
    )
}

