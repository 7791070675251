export class AuthData {
    email: string
    fullName: string
    agencyName: string
    isAgencyAdmin: boolean
    roles: string[] = []
    constructor(email: string, fullName: string, agencyName: string, isAgencyAdmin: string, roles: string[]) {
        this.email = email
        this.fullName = fullName
        this.agencyName = agencyName
        this.isAgencyAdmin = isAgencyAdmin == '1'
        this.roles = roles || []
        if (typeof this.roles == typeof '') {
            this.roles = [roles as unknown as string]
        }
    }
    isInRole(role: string) {
        return this.roles && this.roles.some((x) => x == role)
    }
}
export class Roles {
    public static Operations = 'operations'
    public static TicketsAdmin = 'ticketsadmin'
    public static Guide = 'guide'
    public static Admin = 'admin'
    public static SuperAdmin = 'superadmin'
    public static ArrivalsAdmin = 'arrivalsadmin'
    public static ArrivalsManager = 'arrivalsmanager'
    public static Supplier = 'supplier'
    public static Sales = 'sales'
    public static Accounts = 'accounts'
    public static Agent = 'agent'
    public static Driver = 'driver'
    public static Editor = 'editor'
    public static SuperEditor = 'supereditor'
    public static AirportsRep = 'airportrep'
}
