import * as React from 'react'
import { BaseInputProps } from './BaseProps'
import { Tooltip } from '../Tooltip'
import { NumberFormFieldProps } from './Fields/NumberFormField'
export const TextInput = (props: BaseInputProps) => {
    return (
        <Tooltip text={props.toolTip}>
            <input
                defaultValue={typeof props.defaultValue == 'undefined' ? null : props.defaultValue}
                id={props.id}
                name={props.name}
                // value={props.value || undefined} //{typeof props.value == 'undefined' ? '' : props.value}
                onChange={props.onChange}
                type="text"
                tabIndex={props.tabIndex}
                // ref={props.innerRef}
                {...props.register(props.name, props.options)}
                title={props.toolTip}
                placeholder={props.placeholder}
                autoComplete="off"
            />
        </Tooltip>
    )
}

export const NumberInput = (props: NumberFormFieldProps) => {
    // const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     let value = e.target.value == '' ? undefined : parseFloat(e.target.value)
    //     if (value == NaN) {
    //         value = undefined
    //     }
    //     if (value) {
    //         if (props.min && props.min > value) {
    //             value = props.min
    //         }
    //         if (props.max && props.max < value) {
    //             value = props.max
    //         }
    //     }
    //     if (props.onChange) {
    //         props.onChange(value)
    //     }
    // }
    // const reg = props.register ? { ...props.register(props.name, props.options) } : {}
    return (
        <Tooltip text={props.toolTip || props.placeholder}>
            <input
                placeholder={props.placeholder}
                defaultValue={typeof props.defaultValue == 'undefined' ? '' : props.defaultValue}
                // ref={props.innerRef}
                {...props.register(props.name, {
                    ...props.options,
                    valueAsNumber: true,
                    min: props.min,
                    max: props.max,
                })}
                id={props.id}
                name={props.name}
                tabIndex={props.tabIndex}
                // value={props.value}
                // value={typeof props.value == 'undefined' ? '' : props.value}
                // onChange={onChange}
                step={props.step}
                min={props.min}
                max={props.max}
                type="number"
                autoComplete="off"
            />
        </Tooltip>
    )
}
