import { FilterDateRangeType, FilterType } from '../DataTable/Filters/FilterTypes'

class GenericFilterModelBase {
    fieldName: string = ''
}
// enum FilterType {
//     NONE,
//     TEXT,
//     TEXTNUMBER,
//     SELECT,
//     MULTISELECT,
//     DATERANGE,
//     YEARSELECTOR,
//     DATEPICKER,
// }
// const FilterType = {
//     NONE: 0,
//     TEXT: 1,
//     TEXTNUMBER: 2,
//     SELECT: 3,
//     MULTISELECT: 4,
//     DATERANGE: 5,
//     YEARSELECTOR: 6,
//     DATEPICKER: 7,
//     DATERANGEOPTIONAL: 8,
//     MULTISELECTNUMBER: 9
// }
export class GenericFilterDateRange extends GenericFilterModelBase {
    constructor(fieldName: string, value?: FilterDateRangeType) {
        super()
        this.fieldName = fieldName
        this.value = value
    }
    value?: FilterDateRangeType = undefined
    // from?: moment.Moment = undefined
    // to?: moment.Moment = undefined
}
export class GenericFilterModelText extends GenericFilterModelBase {
    value: string = ''
}
export class GenericFilterModelMultiSelect extends GenericFilterModelBase {
    values: string[] = []
}
export class GenericFilterModelMultiSelectNumber extends GenericFilterModelBase {
    values: number[] = []
}
export class GenericFilterModel {
    page: number = 1
    dateRangeFilters: GenericFilterDateRange[] = []
    textFilters: GenericFilterModelText[] = []
    multiSelectFilters: GenericFilterModelMultiSelect[] = []
    multiSelectFiltersNumber: GenericFilterModelMultiSelectNumber[] = []
    GetDateRangeFilterValue(fieldName: string) {
        const fv = this.dateRangeFilters.find((x) => x.fieldName == fieldName)
        return fv ? fv.value : undefined
    }
    GetTextFilterValue(fieldName: string) {
        const fv = this.textFilters.find((x) => x.fieldName == fieldName)
        return fv ? fv.value : undefined
    }
    GetMultiselectFilterValue(fieldName: string) {
        const fv = this.multiSelectFilters.find((x) => x.fieldName == fieldName)
        return fv ? fv.values : undefined
    }
    GetMultiselectFilterNumberValue(fieldName: string) {
        const fv = this.multiSelectFiltersNumber.find((x) => x.fieldName == fieldName)
        return fv ? fv.values : undefined
    }
    SetFilter(fieldName: string, value: any) {
        if (value.type == FilterType.DATERANGE) {
            const v = value.value as FilterDateRangeType | undefined
            const vObj = new GenericFilterDateRange(fieldName, v)
            if (v && v.startDate && v.endDate && v.startDate.isValid() && v.endDate.isValid()) {
                this.dateRangeFilters = this.dateRangeFilters.some((x) => x.fieldName == fieldName)
                    ? this.dateRangeFilters.map((x) => (x.fieldName == fieldName ? vObj : x))
                    : this.dateRangeFilters.concat([vObj])
            } else {
                this.dateRangeFilters = this.dateRangeFilters.filter((x) => x.fieldName != fieldName)
            }
            this.page = 1
            return
        }
        if (value.type == FilterType.MULTISELECTNUMBER) {
            const MultiSelectFiltersNumber =
                value.value && value.value.length > 0
                    ? this.multiSelectFiltersNumber.some((x) => x.fieldName == fieldName)
                        ? this.multiSelectFiltersNumber.map((x) =>
                            x.fieldName == fieldName
                                ? Object.assign(new GenericFilterModelMultiSelectNumber(), x, { values: value.value })
                                : x,
                        )
                        : this.multiSelectFiltersNumber.concat([
                            Object.assign(new GenericFilterModelMultiSelectNumber(), {
                                values: value.value,
                                fieldName: fieldName,
                            }),
                        ])
                    : this.multiSelectFiltersNumber.filter((x) => x.fieldName != fieldName)
            this.page = 1
            this.multiSelectFiltersNumber = MultiSelectFiltersNumber
            return
        }
        if (value.type == FilterType.MULTISELECT) {
            const MultiSelectFilters =
                value.value && value.value.length > 0
                    ? this.multiSelectFilters.some((x) => x.fieldName == fieldName)
                        ? this.multiSelectFilters.map((x) =>
                            x.fieldName == fieldName
                                ? Object.assign(new GenericFilterModelMultiSelect(), x, { values: value.value })
                                : x,
                        )
                        : this.multiSelectFilters.concat([
                            Object.assign(new GenericFilterModelMultiSelect(), {
                                values: value.value,
                                fieldName: fieldName,
                            }),
                        ])
                    : this.multiSelectFilters.filter((x) => x.fieldName != fieldName)
            this.page = 1
            this.multiSelectFilters = MultiSelectFilters
            return
        }
        if (value.type == FilterType.TEXT) {
            this.page = 1
            this.textFilters =
                value.value && value.value.length > 0
                    ? this.textFilters.some((x) => x.fieldName == fieldName)
                        ? this.textFilters.map((x) =>
                            x.fieldName == fieldName
                                ? Object.assign(new GenericFilterModelText(), x, { value: value.value })
                                : x,
                        )
                        : this.textFilters.concat([
                            Object.assign(new GenericFilterModelText(), { value: value.value, fieldName: fieldName }),
                        ])
                    : this.textFilters.filter((x) => x.fieldName != fieldName)
            return
        }
    }
    sortField: string = ''
    sortType: string = ''
}
