import * as React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { ApplicationState } from '../store'
const ProgressWrapper = styled.span`
    position: absolute;
    height: 20px;
    bottom: 0px;
    width: 100%;
    left: 0;
    background-color: #1b214a55;
`
const ProgressBar = styled.span<{ w: number }>`
    background-color: #1b214a;
    position: absolute;
    width: ${(p) => `${p.w}%`};
    height: 5px;
    bottom: 0px;
    background-color: #1b214a;
    left: 0;
`
const ProgressText = styled.span`
    color: white;
    font-size: 10px;
    margin-top: -2px;
    display: block;
`
interface Props {}
export const MediaUploader = (props: Props) => {
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const uploading = useSelector((state: ApplicationState) => state.photos.uploading)
    return (
        <>
            {uploading.map((f) => (
                <li key={f.guid} className="uploaded-item">
                    <img src={f.data} />
                    <ProgressWrapper>
                        <ProgressBar w={f.progress} />
                        <ProgressText>
                            {f.progress < 100 ? `${f.progress}%` : `${Localization.getString('Processing')}...`}
                        </ProgressText>
                    </ProgressWrapper>
                </li>
            ))}
        </>
    )
}
