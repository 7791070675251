export const toNumber = (val: string) => {
    if (val.length > 0 && val.indexOf(':') > 0) {
        const parts = val.split(':')
        const h = parseInt(parts[0])
        const m = parseInt(parts[1])
        return h * 60 + m
    }
    return 0
}
export const fromNumber = (val: number) => {
    if (typeof val == 'undefined') {
        return ''
    }
    const h = ('0' + Math.floor(val / 60)).slice(-2)
    const m = ('0' + (val % 60)).slice(-2)
    return `${h}:${m}`
}
