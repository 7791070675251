import * as React from 'react'
interface Props extends React.HTMLAttributes<HTMLElement> {
    children: any
}
export const Grid = (props: Props) => (
    <section {...props} className="element-section grid-section">
        {props.children}
    </section>
)

export const Row = (props: Props) => (
    <div {...props} className={`row-wrap ${props.className? props.className :""}`}>
        {props.children}
    </div>
)

interface ColProps extends Props {
    type: ColTypes
}
type ColTypes = '100' | '50' | '33' | '25'
export const Col = (props: ColProps) => <div className={`col-${props.type}`}>{props.children}</div>
