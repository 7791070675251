import { apiCallWrapper } from './apiCallWrapper'
import { EntitiesPaginatedModel } from '../models/EntitiesPaginatedModel'
import { Photo } from '../models/Photo'

export default class PhotosApiService {
    public static endPointBase = '/api/photos'
    static update = async (photo: Photo, dispatch: any) => {
        return await apiCallWrapper<Photo>(PhotosApiService.endPointBase, 'POST', dispatch, photo)
    }
    static getPhotos = async (page: number, dispatch: any) => {
        return await apiCallWrapper<EntitiesPaginatedModel<Photo>>(
            `${PhotosApiService.endPointBase}?page=${page}`,
            'GET',
            dispatch,
        )
    }
}
