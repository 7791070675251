export interface SelectOptionModel {
    label: string
    component?: React.ReactNode
    value: SelectOptionValueType
    disabled?: boolean
    level?: number
    alert?: boolean
    customRenderer?: (data: SelectOptionModel) => any
    options?: SelectOptionModel[]
}
export type SelectOptionValueType = string | number | boolean | undefined
export const defaultLabelSort = (a: SelectOptionModel, b: SelectOptionModel) =>
    a.label > b.label ? 1 : a.label < b.label ? -1 : 0
export const DefaultOptionsSortFunc = (a: SelectOptionModel, b: SelectOptionModel) => (a.label > b.label ? 1 : -1)
