import { NotificationModel, NotificationType } from '../Notifications/NotificationModel'
import { actionCreators as loadingActions } from '../store/Loading'
import { actionCreators as notificationsActions } from '../store/Notifications'
import { getToken } from '../Utils/authUtils'
export interface ApiResult<T> {
    IsOk: boolean
    ErrorTitle: string
    ErrorMessage: any
    Content?: T
    Code: number
}
export interface ApiFileResult extends ApiResult<Blob>{
    FileName: string | undefined
}
export const apiCallWrapper = async <T>(
    url: string,
    method: string,
    dispatch: (action: any) => void,
    payload?: any,
): Promise<ApiResult<T>> => {
    dispatch(loadingActions.incrementLoading())
    const data = await apiCallWrapperNoDispatch<T>(url, method, payload)
    if (data.Code === 401) {
        dispatch({ type: 'UNAUTH_USER' })
        data.IsOk = false
        data.ErrorMessage = 'Unauthorized'
    }
        dispatch(loadingActions.decrementLoading())
    if (!data.IsOk && notificationsActions && notificationsActions.add) {
        dispatch(
            notificationsActions.add(
                new NotificationModel(
                    NotificationType.error,
                    data.Code?.toString() ?? '' + ' ' + data.ErrorTitle,
                    data.ErrorMessage,
                ),
            ),
        )
    }
    return data
}

export const apiCallWrapperNoDispatch = async <T>(
    url: string,
    method: string = 'GET',
    payload?: any,
    setLoading?: (value: React.SetStateAction<boolean>) => void,
): Promise<ApiResult<T>> => {
    const result = {
        IsOk: true,
        ErrorTitle: '',
        ErrorMessage: '',
        Content: (undefined as unknown) as T,
        Code: 0,
    }
    if (setLoading) {
        setLoading(true)
    }
    try {
        const body = payload && JSON.stringify(payload)
        const token = getToken()
        const response = await fetch(url, {
            method,
            body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token ? 'Bearer ' + token : '',
            },
        })

        result.Code = response.status
        const json = (await response.json()) as T
        result.Content = json
        if (setLoading) {
            setLoading(false)
        }
    } catch (error: any) {
        result.IsOk = false
        result.ErrorTitle = error.message
        result.ErrorMessage = error.stack
    }
    return result
}

export const apiCallWrapperNoDispatchBlob = async (
    url: string,
    method: string = 'GET',
    payload?: any,
    setLoading?: (value: React.SetStateAction<boolean>) => void,
): Promise<ApiFileResult> => {
    const result = {
        IsOk: true,
        ErrorTitle: '',
        ErrorMessage: '',
        Content: (undefined as unknown) as Blob,
        Code: 0,        
    } as ApiFileResult
    if (setLoading) {
        setLoading(true)
    }
    try {
        const body = payload && JSON.stringify(payload)
        const token = getToken()
        const response = await fetch(url, {
            method,
            body,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: token ? 'Bearer ' + token : '',
            },
        })

        result.Code = response.status
        const blob = await response.blob()
        const filename =  response.headers.get('Content-Disposition')?.split('filename=')[1].split(';')[0]
        result.Content = blob
        result.FileName = filename;
        if (setLoading) {
            setLoading(false)
        }
    } catch (error: any) {
        result.IsOk = false
        result.ErrorTitle = error.message
        result.ErrorMessage = error.stack
    }
    return result
}

