import { guid } from '../services/guid'
import { Photo } from '../models/Photo'

export class FileUploading {
    constructor(file: File) {
        this.guid = guid()
        this.file = file
    }
    guid: string
    data?: string
    file: File
    photo?: Photo
    progress: number = 0
}
