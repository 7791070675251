import * as React from 'react'
import './styles.scss'
import { ButtonBack, ButtonNext, CarouselProvider, Slide, Slider } from 'pure-react-carousel';
// import { ReactComponent as IconDropdownLarge } from '../assets/images/icons';
// import { ReactComponent as IconChecked } from '../assets/images/icons/icon-checked.svg';
// import { ReactComponent as IconBlocked } from '../assets/images/icons/icon-checked.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBan, faChevronLeft, faChevronRight } from '@fortawesome/fontawesome-free-solid';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const IconLeft = () => <FontAwesomeIcon icon={faChevronLeft as IconProp}/>
const IconRight = () => <FontAwesomeIcon icon={faChevronRight as IconProp}/>
const IconChecked = () => <FontAwesomeIcon icon={faCheck as IconProp} />
const IconBlocked = () => <FontAwesomeIcon icon={faBan as IconProp} />

import format from 'date-fns/format';

export interface DateCarouselDate{
  isAvailable: boolean, date: Date
}
type Props = {
    dates: DateCarouselDate[]
    onSelectDate: (date: Date) => void
}
export const DateCarousel = (props: Props) => {
    const {dates} = props
    return <CarouselProvider
              naturalSlideWidth={95}
              naturalSlideHeight={63}
              totalSlides={dates.length}
              visibleSlides={3}
              className="carousel"
            >
              <ButtonBack className="carousel__button carousel__button_back">
                <IconLeft />
              </ButtonBack>
              <Slider className="carousel__slider">
                {dates.map((date, index) => {
                //   const date = parseJSON(price.date);                  
                  return (
                    <Slide key={index} index={index} onClick={ date.isAvailable ? () => props.onSelectDate(date.date) : undefined}>
                      <div className={`carousel__item ${date.isAvailable ? ' carousel__item_active' : 'carousel__item_inactive'}`}>
                        <span>{format(date.date, 'iii')}</span> {format(date.date, 'd MMM')}
                        {date.isAvailable && <IconChecked />}
                        {!date.isAvailable && <IconBlocked />}
                      </div>
                    </Slide>
                  );
                })}
              </Slider>
              <ButtonNext className="carousel__button carousel__button_next">
                <IconRight />
              </ButtonNext>
            </CarouselProvider>
}