import * as React from 'react'
import { SelectOptionModel, SelectOptionValueType } from './SelectOptionModel'
import SelectContainer from './SelectContainer'
import { useOnCloseElement } from '../Effects/useOnCloseElement'
export interface SelectProps {
    hideIfNoOptions?: boolean
    disabled?: boolean
    isMultiple?: boolean
    isClearable?: boolean
    isAutoComplete?: boolean
    isNotSearchable?: boolean
    tabIndex?: number
    wrapperClassName?: string
    placeholder?: string
    value?: SelectOptionValueType | SelectOptionValueType[]
    title?: string
    icon?: string
    zIndex?: number
    style?: React.CSSProperties
    bodyStyle?: React.CSSProperties
    customValue?: string
    autoCompleteEndPoint?: string
    autoCompleteToken?: () => string | null
    appendClass?: string
    autoCompleteResponseHandler?: (data: any) => SelectOptionModel[]
    onClear?: () => void
    onChange?: (options: SelectOptionModel) => void
    onChangeMulti?: (options: SelectOptionModel[]) => void
    onInputChanged?: (value: string) => void
    options: SelectOptionModel[]
    // wrapper?:  StyledComponentClass<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>, any, React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>>
}

export const Select = (props: SelectProps) => {
    let autoCompleteTimeOut: any = undefined
    // const selectedItem = props.options.find((x) => x.value)
    const [isOpened, setIsOpened] = React.useState(false)
    const [search, setSearch] = React.useState('')
    const [options, setOptions] = React.useState([] as SelectOptionModel[])
    const [isLoading, setIsLoading] = React.useState(false)
    const [preSelectedOption, setPreselectedOption] = React.useState((undefined as unknown) as SelectOptionModel)
    React.useEffect(() => {
        setOptions(props.options)
    }, [props.options])
    const onPreSelectOption = (opt: SelectOptionModel) => {
        setPreselectedOption(opt)
    }
    const close = () => {
        setIsOpened(false)
        if (!props.isAutoComplete && !props.onInputChanged) {
            setSearch('')
        }
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    const open = () => {
        if (!props.disabled && !isOpened) {
            setIsOpened(true)
        }
    }
    const toggle = (e: any) => {
        e.stopPropagation()
        const stops = ['preselected']
        if (props.disabled) {
            setIsOpened(false)
            return
        }
        if (!(e.target.className == '') && !stops.some((x) => e.target.className.indexOf(x) >= 0)) {
            setIsOpened(!isOpened)
        }
    }
    const onRemove = (opt: SelectOptionModel) => {
        if (props.onChangeMulti)
            props.onChangeMulti(((selectedOption || []) as SelectOptionModel[]).filter((x) => x != opt))
    }
    const onSelect = (opt: SelectOptionModel) => {
        if (props.isMultiple) {
            if (props.onChangeMulti) {
                const prevValue = (selectedOption || []) as SelectOptionModel[]
                const newValue = prevValue.some((x) => x.value == opt.value)
                    ? prevValue.filter((x) => x.value != opt.value)
                    : prevValue.concat([opt])
                console.log(newValue)
                props.onChangeMulti(newValue)
            }
        } else {
            if (props.onChange) {
                props.onChange(opt)
            }
            close()
        }
    }
    const preSelectFirstItem = (options: SelectOptionModel[]) => {
        if (options.length > 0) {
            preSelectOption(options[0])
        }
    }
    const preSelectLastItem = (options: SelectOptionModel[]) => {
        if (options.length > 0) {
            preSelectOption(options[options.length - 1])
        }
    }
    const preSelectNextItem = (preSelectedItem: SelectOptionModel, options: SelectOptionModel[], inc = 1) => {
        let index = -1
        if (preSelectedItem) {
            index = options.indexOf(preSelectedItem)
        }
        for (let i = Math.min(index + inc, options.length - 1); i < options.length; i++) {
            if (options[i] && !options[i].options) {
                preSelectOption(options[i])
                break
            }
        }
    }
    const preSelectPrevItem = (preSelectedItem: SelectOptionModel, options: SelectOptionModel[], inc = 1) => {
        let index = -1
        if (preSelectedItem) {
            index = options.indexOf(preSelectedItem)
        }
        for (let i = Math.max(0, index - inc); i >= 0; i--) {
            if (options[i] && !options[i].options) {
                preSelectOption(options[i])
                break
            }
        }
    }
    const preSelectOption = (opt: SelectOptionModel) => {
        if (preSelectedOption !== opt) {
            setPreselectedOption(opt)
        }
    }
    const keyPressed = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.keyCode == 27) {
            close()
        } else {
            open()
            switch (event.keyCode) {
                case 40: //arrow Down
                    event.preventDefault()
                    preSelectNextItem(preSelectedOption, options)
                    break
                case 38: //arrow up
                    event.preventDefault()
                    preSelectPrevItem(preSelectedOption, options)
                    break
                case 33: //page up
                    event.preventDefault()
                    preSelectPrevItem(preSelectedOption, options, 10)
                    break
                case 34: //page down
                    event.preventDefault()
                    preSelectNextItem(preSelectedOption, options, 10)
                    break
                case 36: //home
                    event.preventDefault()
                    preSelectFirstItem(options)
                    break
                case 35: //end
                    event.preventDefault()
                    preSelectLastItem(options)
                    break
                case 13: //on enter
                    if (preSelectedOption) {
                        onSelect(preSelectedOption)
                    }
                    break
                default:
                    break
            }
        }
    }
    const getSelectedOptionMulti = (options: SelectOptionModel[], values?: SelectOptionValueType[]) => {
        if (!values || !values.length) return []
        let selectedOptions = options.filter((x) => values.some((v) => v == x.value))
        return selectedOptions
    }
    const getSelectedOption = (options: SelectOptionModel[], value?: SelectOptionValueType) => {
        // if (typeof value == 'undefined') return undefined
        let selectedOption = options.find((x) => x.value == value)
        if (value && !selectedOption) {
            for (let i = 0; i < options.length; i++) {
                const option = options[i]
                if (option.options) {
                    selectedOption = option.options.find((x) => x.value == value)
                }
                if (selectedOption) break
            }
        }
        return selectedOption
    }
    const requestApi = async (search: string) => {
        setIsLoading(true)
        const url = `${props.autoCompleteEndPoint}&search=${search}`
        const headers = {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }
        if (props.autoCompleteToken) {
            const token = props.autoCompleteToken()
            headers['Authorization'] = 'Bearer ' + token
        }
        const response = await fetch(url, {
            headers,
            credentials: 'include',
        })
        const json = await response.json()
        if (json) {
            const options = props.autoCompleteResponseHandler
                ? props.autoCompleteResponseHandler(json)
                : (json as SelectOptionModel[])
            setIsLoading(false)
            setOptions(options)
        }
    }
    const sortBySearchIndex = (search: string) => (a: SelectOptionModel, b: SelectOptionModel) => {
        if ((a.options && b.options) || a.value == 0 || b.value == 0) return 0
        const aLabel = (a.label || '').toLowerCase()
        const bLabel = (b.label || '').toLowerCase()
        let indexInA = aLabel.indexOf(search)
        let indexInB = bLabel.indexOf(search)
        if (indexInA < indexInB) {
            return -1
        } else if (indexInA > indexInB) {
            return 1
        } else {
            if (aLabel.length < bLabel.length) {
                return -1
            } else if (aLabel.length > bLabel.length) {
                return 1
            } else {
                return aLabel > bLabel ? 1 : -1
            }
        }
    }
    const searchOptions = (options: SelectOptionModel[], search: string) => {
        if (search && search.length > 0) {
            search = search.toLowerCase()
            options = options
                .reduce((res, x) => {
                    if (x.options && x.options.length > 0) {
                        const options = x.options
                            .filter((z) => z.label && z.label.toLowerCase().indexOf(search) >= 0)
                            .sort(sortBySearchIndex(search))
                        if (options.length > 0) {
                            res.push(Object.assign({}, x, { options }))
                        }
                    } else {
                        if (x.label.toLowerCase().indexOf(search) >= 0) {
                            res.push(x)
                        }
                    }
                    return res
                }, [] as SelectOptionModel[])
                .sort(sortBySearchIndex(search))
        }
        return options.reduce((res, x) => {
            res.push(x)
            if (x.options && x.options.length > 0) {
                res = res.concat(x.options.map((opt) => Object.assign(opt)))
            }
            return res
        }, [] as SelectOptionModel[])
    }
    React.useEffect(() => {
        if (props.onInputChanged) {
            console.log('ON inpu ch: ' + search)
            props.onInputChanged(search)
        }
        if (props.autoCompleteEndPoint) {
            if (autoCompleteTimeOut) {
                clearTimeout(autoCompleteTimeOut)
            }
            autoCompleteTimeOut = setTimeout(() => {
                requestApi(search)
            }, 500)
        } else {
            let options = searchOptions(props.options, search)
            setSearch(search)
            setOptions(options)
        }
    }, [search, props.options])
    const changeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.disabled) {
            const search = e.target.value

            setSearch(search)
        }
    }
    const selectedOption = React.useMemo(
        () =>
            props.isMultiple
                ? getSelectedOptionMulti(options, props.value as SelectOptionValueType[])
                : getSelectedOption(options, props.value as SelectOptionValueType),
        [props.isMultiple, props.value, options],
    )
    const selectedLabel = selectedOption && !props.isMultiple ? (selectedOption as SelectOptionModel).label : undefined
    return props.isNotSearchable ? (
        <div ref={ref as any} onClick={toggle} className={`custom_select_wrapper ${props.wrapperClassName || ''}`}>
            <div className="custom_select">
                {/* {selectedOption?.value} */}
                {selectedOption && !props.isMultiple
                    ? (selectedOption as SelectOptionModel).customRenderer
                        ? (selectedOption as any).customRenderer(selectedOption)
                        : selectedLabel
                    : props.placeholder}
            </div>
            <SelectContainer
                selectedOption={selectedOption}
                preSelectedItem={preSelectedOption}
                options={options}
                isOpened={isOpened}
                onRemove={onRemove}
                onSelect={onSelect}
                onHover={onPreSelectOption}
                isMultiple={props.isMultiple || false}
            />
            <div className="custom_select_toggle"></div>
        </div>
    ) : (
        <div ref={ref as any} onClick={toggle} className="input-wrap--select">
            <div className={`custom_select_wrapper${props.isMultiple ? ' is-multiple' : ''}`}>
                <div className={`custom_select ${props.options.length == 0 ? 'no-options' : ''}`}>
                    <input
                        placeholder={selectedLabel || props.placeholder}
                        onKeyDown={keyPressed}
                        className={`${props.appendClass ? `${props.appendClass} ` : ''}${
                            props.isClearable ? 'clearable ' : ''
                        }${props.disabled ? ' disabled' : ''}`}
                        onFocus={open}
                        onClick={open}
                        readOnly={props.disabled}
                        disabled={props.isNotSearchable}
                        value={
                            isOpened
                                ? props.isAutoComplete
                                    ? props.customValue
                                    : search || ''
                                : selectedLabel
                                ? selectedLabel
                                : props.customValue || (props.isAutoComplete ? search || '' : '')
                        }
                        onChange={changeSearch}
                    />
                </div>
                <SelectContainer
                    selectedOption={selectedOption}
                    preSelectedItem={preSelectedOption}
                    options={options}
                    onRemove={onRemove}
                    isOpened={isOpened && (!props.hideIfNoOptions || options.length > 0)}
                    onSelect={onSelect}
                    isMultiple={props.isMultiple || false}
                    onHover={onPreSelectOption}
                />
                <div className="custom_select_toggle"></div>
            </div>
        </div>
    )
}
