import * as React from 'react'
import {SelectOptionModel} from './SelectOptionModel'
interface Props {
    option: SelectOptionModel
    level?: number
    isSelected: boolean
    isPreselected: boolean
    children: any
    onSelect: (option: SelectOptionModel) => void
    onHover: (options: SelectOptionModel) => void
}
const SelectOption = (props: Props) => {
    const onSelect = () => {
        props.onSelect(props.option)
    }
    const onHover = () => {
        props.onHover(props.option)
    }
    const { isSelected, isPreselected } = props
    return (
        <li
            className={`${props.level ? 'level-' + props.level : ''}${isSelected ? ' is-selected' : ''}${
                isPreselected ? ' preselected' : ''
            }`}
            onMouseDown={onSelect}
            onMouseEnter={onHover}
        >
            {props.children}
        </li>
    )
}
export default SelectOption
