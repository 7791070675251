import styled from 'styled-components'

interface PInTableListProps {
    fontSize?: string
}
export const PInTableList = styled.div<PInTableListProps>`
    font-size: ${(props) => props.fontSize || '10px'};
    margin: 0;
`

