import * as React from 'react'
import './loadingBlock.scss'

export const LoadingBlock = () => (
    <div className="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
    </div>
)
