import * as React from 'react'
import InputBaseProps from './InputBaseProps'
interface InputNumberProps extends InputBaseProps {
    step?: number
    min?: number
    max?: number
}
const InputNumber = (props: InputNumberProps) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseFloat(e.target.value)
        props.onChange(value)
    }
    return (
        <input
            style={{ width: '100%' }}
            placeholder={props.placeholder}
            type="number"
            step={props.step || 1}
            min={props.min}
            max={props.max}
            value={props.value || ''}
            onChange={onChange}
        />
    )
}
export default InputNumber
