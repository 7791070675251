import * as React from 'react'
import { BaseFormFieldProps } from '../BaseProps'
import { FieldFooter } from '../FieldFooter'
import { FormFieldLabel } from './FormFieldLabel'
import { FormFieldWrapper } from './FormFieldWrapper'

export const TextAreaField = (props: BaseFormFieldProps) => (
    <FormFieldWrapper {...props}>
        {props.label && <FormFieldLabel {...props} />}
        <textarea
            tabIndex={props.tabIndex}
            name={props.name}
            defaultValue={props.defaultValue || ''}
            // ref={props.innerRef}
            {...props.register(props.name, props.options)}
            id={props.id}
            onChange={props.onChange}
        />
        <FieldFooter error={props.error} errorDescription={props.errorDescription} />
    </FormFieldWrapper>
)
