import * as React from 'react'
import styled from 'styled-components'
interface Props {
    text?: string
    richText?: React.ReactElement
    children: any
    style?: StyleProps
    className?: string
}
interface StyleProps {
    width?: number
    display?: string
}
const TooltipWrapper = styled.div<StyleProps>`
    display: inline;
    .tooltip-wrap {
        display: none;
        margin-left: 18px;
        text-align: center;
        width: ${(p) => (p.width ? p.width + 'px' : 'auto')};
        p {
            font-size: inherit;
            color: inherit;
            line-height: 14px;
            margin-bottom: 5px;
        }
    }
    :hover {
        .tooltip-wrap {
            display: block;
        }
    }
`
export const Tooltip = (props: Props) => {
    const text = props.richText ?? (props.text && props.text.length > 0 ? props.text : undefined)
    return text ? (
        <TooltipWrapper className={props.className} display={props.style?.display} width={props.style?.width}>
            {props.children}
            <div className="tooltip-wrap">{text}</div>
        </TooltipWrapper>
    ) : (
        props.children
    )
}
