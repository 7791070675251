import * as React from 'react'
import { Controller } from 'react-hook-form'

export const IdFormField = (props: { name?: string; control: any }) => (
    <Controller
        name={props.name || 'id'}
        render={({ field }) => (
            <input
                {...props}
                onChange={(e) => field.onChange(parseInt(e.target.value, 10))}
                hidden
                value={field.value}
                name={props.name || 'id'}
                type="number"
            />
        )}
        control={props.control}
    />
)
