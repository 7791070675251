import { useEffect } from 'react'

export const useOnCloseElement = (ref: any, onClose: () => void) => {
    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape') {
            onClose()
        }
    }

    const handleClick = (event: Event) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
            onClose()
        }
    }
    useEffect(() => {
        document.addEventListener('mousedown', handleClick)
        document.addEventListener('touchstart', handleClick)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('mousedown', handleClick)
            document.removeEventListener('touchstart', handleClick)
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [ref, onClose])
}
