import * as React from 'react'

interface Props {
    title?: string
    onClick: () => void
    isOpen: boolean
    isActive: boolean
}
class FilterIcon extends React.Component<Props, {}> {
    render() {
        return (
            <i
                title={this.props.title}
                onClick={() => {
                    this.props.onClick()
                }}
                className={`data-table-filter-icon fa fa-filter${this.props.isOpen ? ' open' : ''}${
                    this.props.isActive ? ' active' : ''
                }`}
            ></i>
        )
    }
}
export default FilterIcon
