import './layout.scss'
import * as React from 'react'
import { ToTop } from '../ToTop'
export const Layout = (props: { children?: React.ReactNode }) => {
    return (
        <React.Fragment>
            <ToTop />
            {props.children}
        </React.Fragment>
    )
}
