import { FileUploading } from './../serviceModels/File'
import { Reducer, Action } from 'redux'
import { EntitiesPaginatedModel } from '../models/EntitiesPaginatedModel'
import { Photo } from '../models/Photo'
import { AppThunkAction } from '.'
import * as LoadingStore from './Loading'
import PhotosApiService from '../services/PhotosService'
import { PaginationModel } from '../models/PaginationModel'
import UploaderWithProgress from '../services/UploaderWithProgress'
export interface State {
    photos?: EntitiesPaginatedModel<Photo>
    edit?: Photo
    delete?: Photo
    uploaders: UploaderWithProgress[]
    uploading: FileUploading[]
}
const PHOTOS_RESPONSE = 'A_PHOTOS_RESPONSE'
interface PhotosResponse {
    type: 'A_PHOTOS_RESPONSE'
    payload: EntitiesPaginatedModel<Photo>
}
const PHOTO_UPLOAD = 'A_PHOTO_UPLOAD'
interface PhotoUpload {
    type: 'A_PHOTO_UPLOAD'
    payload: FileUploading
    uploader: UploaderWithProgress
}
const PHOTO_DELETE = 'A_PHOTO_DELETE'
interface PhotoDelete {
    type: 'A_PHOTO_DELETE'
    payload: Photo
}
const PHOTO_EDIT = 'A_PHOTO_EDIT'
interface PhotoEdit {
    type: 'A_PHOTO_EDIT'
    payload: Photo
}
const PHOTO_UPDATED = 'A_PHOTO_UPDATED'
interface PhotoUpdated {
    type: 'A_PHOTO_UPDATED'
    payload: Photo
}
const PHOTO_UPLOAD_PROGRESS = 'A_PHOTO_UPLOAD_PROGRESS'
interface PhotoUploadProgress {
    type: 'A_PHOTO_UPLOAD_PROGRESS'
    guid: string
    progress: number
}
const PHOTO_UPLOADED = 'A_PHOTO_UPLOADED'
interface PhotoUploaded {
    type: 'A_PHOTO_UPLOADED'
    guid: string
    payload?: Photo
}
type KnownAction =
    | PhotosResponse
    | PhotoUploaded
    | PhotoUpload
    | PhotoEdit
    | PhotoDelete
    | PhotoUpdated
    | PhotoUploadProgress

export const actionCreators = {
    edit: (photo?: Photo) => ({ type: PHOTO_EDIT, payload: photo }),
    delete: (photo: Photo) => ({ type: PHOTO_DELETE, payload: photo }),
    update: (photo: Photo): AppThunkAction<KnownAction> => async (dispatch) => {
        const p = await PhotosApiService.update(photo, dispatchEvent)
        if (p.IsOk && p.Content) {
            dispatch({ type: PHOTO_UPDATED, payload: Photo.Create(p.Content) })
        }
    },
    upload: (file: FileUploading): AppThunkAction<KnownAction> => async (dispatch) => {
        const uploader = new UploaderWithProgress({
            file,
            guid: file.guid,
            url: '/api/photos/upload',
            onProgress: (progress: number) => {
                dispatch({ type: PHOTO_UPLOAD_PROGRESS, guid: file.guid, progress })
            },
            onUploaded: () => {
                dispatch({ type: PHOTO_UPLOAD_PROGRESS, guid: file.guid, progress: 100 })
            },
            onFinished: (photo: Photo) => {
                dispatch({ type: PHOTO_UPLOADED, guid: file.guid, payload: photo })
            },
        })
        dispatch({ type: PHOTO_UPLOAD, payload: file, uploader })
    },
    uploaded: (photo: Photo) => ({ type: PHOTO_UPLOADED, payload: photo }),
    requestPhotos: (page: number): AppThunkAction<KnownAction | LoadingStore.KnownAction> => async (dispatch) => {
        let fetchTask = await PhotosApiService.getPhotos(page, dispatch)
        if (fetchTask.IsOk && fetchTask.Content) {
            dispatch({
                type: PHOTOS_RESPONSE,
                payload: Object.assign(new EntitiesPaginatedModel<Photo>(), {
                    entities: fetchTask.Content.entities.map((x: any) => Photo.Create(x)),
                    pagingInfo: PaginationModel.Create(fetchTask.Content.pagingInfo),
                }),
            })
        }
    },
}
const unloadedState: State = {
    photos: undefined,
    uploading: [] as FileUploading[],
    uploaders: [] as UploaderWithProgress[],
}
export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case PHOTO_UPDATED:
            return {
                ...state,
                edit: undefined,
                photos: Object.assign(new EntitiesPaginatedModel<Photo>(), state.photos, {
                    entities: state.photos?.entities.map((x) => (x.id == action.payload.id ? action.payload : x)),
                }),
            }
        case PHOTO_EDIT:
            return { ...state, edit: action.payload }
        case PHOTO_UPLOAD_PROGRESS:
            return {
                ...state,
                uploading: state.uploading.map((x) =>
                    x.guid == action.guid
                        ? Object.assign(new FileUploading(x.file), x, { progress: action.progress })
                        : x,
                ),
            }
        case PHOTOS_RESPONSE:
            return { ...state, photos: action.payload }
        case PHOTO_UPLOAD:
            return {
                ...state,
                uploading: state.uploading.concat(action.payload),
                uploaders: state.uploaders.concat(action.uploader),
            }
        case PHOTO_UPLOADED:
            return {
                ...state,
                uploading: state.uploading.filter((x) => x.guid != action.guid),
                uploaders: state.uploaders.filter((x) => x.guid != action.guid),
                photos: Object.assign(new EntitiesPaginatedModel<Photo>(), {
                    entities: [action.payload].concat(state.photos?.entities ?? []),
                    pagingInfo: Object.assign(
                        PaginationModel.Create(state.photos?.pagingInfo ?? new PaginationModel()),
                    ),
                }),
            }
    }
    return state || unloadedState
}
