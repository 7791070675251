import { guid } from '../services/guid'

export enum NotificationType {
    information = 0,
    warning,
    error,
    success,
}
export class NotificationModel {
    constructor(type: NotificationType, title: string, message: string) {
        this.guid = guid()
        this.title = title
        this.message = message
        this.type = type
    }
    guid: string
    title: string
    message: string
    type: NotificationType
}
