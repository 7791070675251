import { InputType } from './Inputs/InputType'
import { FilterType } from './Filters/FilterTypes'
import { SelectOptionModel } from '../Select/SelectOptionModel'

interface IDataTableColumnModelParameters {
    displayName: string
    fieldName?: string
    filterFieldName?: string
    width?: number
    isHidden?: boolean
    filterType?: any
    filterValue?: any
    filterOptions?: SelectOptionModel[]
    customEditComponent?: (obj: any, onChange: (value: any, fieldName: string) => void) => React.ReactNode
    customRenderer?: (obj: any) => void
    customFilterFunc?: (filterValue: any, data: any[]) => any[]
    isUseCustomRendererForExcel?: boolean
    customExcelRenderer?: (obj: any) => void
    customSumFunc?: (obj: any) => number
    customHeaderComponent?: () => void
    customCellClassFunction?: (obj: any) => string
    customCellStyleFunction?: (obj: any) => React.CSSProperties
    showSum?: boolean
    showAve?: boolean
    sortable?: boolean
    sortFunc?: (obj1: any, obj2: any) => void
    isExportExcel?: boolean
    title?: string
    placeholder?: string
    isEditable?: boolean
    isEditableClause?: (obj: any) => boolean
    editType?: InputType
    editStep?: number
    isClearable?: boolean
    editOptions?: SelectOptionModel[]
    onFilterValueChanged?: (fieldName: string, value: any) => void
}
export class DataTableColumnModel {
    constructor(params: IDataTableColumnModelParameters) {
        const {
            displayName,
            fieldName,
            width,
            filterType,
            isHidden,
            isUseCustomRendererForExcel,
            filterOptions,
            filterValue,
            customRenderer,
            showSum,
            showAve,
            customSumFunc,
            customEditComponent,
            sortable,
            sortFunc,
            isExportExcel,
            customExcelRenderer,
            customHeaderComponent,
            onFilterValueChanged,
            customCellClassFunction,
            customCellStyleFunction,
            title,
            placeholder,
            isClearable,
            isEditable,
            isEditableClause,
            editOptions,
            editStep,
            editType,
            filterFieldName,
            customFilterFunc,
        } = params
        this.displayName = displayName
        this.onFilterValueChanged = onFilterValueChanged
        this.customHeaderComponent = customHeaderComponent
        this.customCellClassFunction = customCellClassFunction
        this.customCellStyleFunction = customCellStyleFunction
        this.fieldName = fieldName || ''
        this.filterFieldName = filterFieldName
        this.width = width || 80
        this.filterType = filterType || FilterType.NONE
        this.filterValue = filterValue
        this.filterOptions = filterOptions
        this.customRenderer = customRenderer
        this.customFilterFunc = customFilterFunc
        this.customExcelRenderer = customExcelRenderer
        this.showSum = showSum || false
        this.showAve = showAve || false
        this.customEditComponent = customEditComponent
        this.customSumFunc = customSumFunc
        this.sortable = sortable || false
        this.sortFunc = sortFunc
        this.isClearable = isClearable
        this.isEditable = isEditable
        this.isEditableClause = isEditableClause
        this.isExportExcel = typeof isExportExcel == 'undefined' ? true : isExportExcel
        this.title = title
        this.placeholder = placeholder
        this.editType = editType
        this.editStep = editStep
        this.editOptions = editOptions
        this.isHidden = isHidden
        this.isUseCustomRendererForExcel = isUseCustomRendererForExcel
    }
    displayName: string
    fieldName: string
    filterFieldName?: string
    onFilterValueChanged?: (fieldName: string, value: any) => void
    width: number
    filterType: any
    filterValue: any
    filterOptions: any
    customHeaderComponent?: () => void
    customCellClassFunction?: (obj: any) => string
    customCellStyleFunction?: (obj: any) => React.CSSProperties
    customEditComponent?: (obj: any, onChange: (value: any, fieldName: string) => void) => React.ReactNode
    customRenderer?: (obj: any) => void
    customFilterFunc?: (filterValue: any, data: any[]) => any[]
    isUseCustomRendererForExcel?: boolean
    customExcelRenderer?: (obj: any) => void
    customSumFunc?: (obj: any) => number
    showSum: boolean
    showAve: boolean
    sortable: boolean
    sortFunc: any
    isExportExcel: boolean
    title?: string
    placeholder?: string
    isEditable?: boolean
    isHidden?: boolean
    isEditableClause?: (obj: any) => boolean
    editType?: InputType
    editOptions?: SelectOptionModel[]
    editStep?: number
    isClearable?: boolean
}
