import * as React from 'react'
interface Props {
    error?: string | React.ReactElement
    errorDescription?: string
    type?: FieldFooterType
}
type FieldFooterType = 'warning' | 'info' | 'error'
export const FieldFooter = (props: Props) => {
    return (
        <div
            className={`${props.type ? props.type : ''} field-footer${props.error ? ' has-error-message' : ''} ${
                props.errorDescription ? ' has-description' : ''
            }`}
        >
            {props.error && <span className="field-state">{props.error}</span>}

            {props.errorDescription && (
                <span className="field-description">
                    <i></i>
                    {props.errorDescription}
                </span>
            )}
        </div>
    )
}
