import * as React from 'react'
import { ReactNode } from 'react';
import './Button.styles.scss'

type ButtonProps = {
    color?: 'blue' | 'orange' | 'green' | 'gray';
    size?: 'small' | 'medium' | 'large';
    label?: string;
    disabled?: boolean;
    load?: boolean;
    iconRight?: () => ReactNode;
    iconLeft?: () => ReactNode;
    active?: boolean;
    onClickHandler?: () => void;
    submit?: boolean;
    fullWidth?: boolean;
    className?: string
  };
  
export const Button: React.FC<ButtonProps> = (props) => {
    let buttonClass = 'b-button ' + (props.className || '');
    buttonClass = props.fullWidth ? `${buttonClass} button_fullWidth` : buttonClass;
    switch (props.color) {
      case 'blue':
        buttonClass = `${buttonClass} button_blue`;
        break;
      case 'orange':
        buttonClass = `${buttonClass} button_orange`;
        break;
      case 'green':
        buttonClass = `${buttonClass} button_green`;
        break;
      case 'gray':
        buttonClass = `${buttonClass} button_gray`;
        break;
    }
    switch (props.size) {
      case 'small':
        buttonClass = `${buttonClass} button_small`;
        break;
      case 'medium':
        buttonClass = `${buttonClass} button_medium`;
        break;
      case 'large':
        buttonClass = `${buttonClass} button_large`;
        break;
    }
  
    return (
      <button
        type={props.submit ? 'submit' : 'button'}
        disabled={props.disabled || props.load}
        data-active={props.active ? props.active : undefined}
        className={buttonClass}
        onClick={props.onClickHandler}
      >
        {/*{props.load && <Loader {...props} />}*/}
        <span {...props} data-one-icon={!props.label}>
          {!props.load && (
            <>
              {props.iconLeft ? props.iconLeft() : null}
              {props.label}
              {props.iconRight ? props.iconRight() : null}
            </>
          )}
        </span>
      </button>
    );
  };
  
  