import { AuthData } from '../models/AuthData'
// import { USER_PROFILE_IMAGE } from "../../constants/UserProfileImage";

let serverSideTokenValue = ''
export const setServerSideToken = (token: string) => {
    serverSideTokenValue = token
}
export const getToken = () => {
    if (typeof localStorage !== 'undefined') {
        const token = localStorage.getItem('token')
        return token
    }

    return serverSideTokenValue
}
export const getParsedToken = () => {
    const token = getToken()
    if (token && typeof window !== 'undefined') {
        const spl = token.split('.')
        if (spl.length > 1) {
            const base64Url = spl[1]
            const base64 = base64Url.replace('-', '+').replace('_', '/')
            const json = JSON.parse(window.atob(base64))
            return new AuthData(
                json.sub,
                json.FullName,
                json.AgencyName,
                json.IsAgencyAdmin,
                json['http://schemas.microsoft.com/ws/2008/06/identity/claims/role'],
            )
        }
    }
    return undefined
}
export const getRedirectableCurrentUrl = () => {
    if (typeof document !== 'undefined') {
        const curPath = document.location && document.location.pathname
        const returnUrl = curPath.replace(/\//g, '_')
        return returnUrl
    } else {
        return ''
    }
}
