import { Action, Reducer } from 'redux'
export interface State {
    dataTableExportExcelEndpoint: string
}
export const SET_DATATABLE_EXPORT_EXCEL_ENDPOINT = 'SET_DATATABLE_EXPORT_EXCEL_ENDPOINT'
export interface SetDataTableExportExcelEndpoint {
    type: 'SET_DATATABLE_EXPORT_EXCEL_ENDPOINT'
    payload: string
}
export type KnownAction =
    | SetDataTableExportExcelEndpoint

export const actionCreators = {
    setDataTableExportExcelEndpoint: (endPoint: string) =>
        <SetDataTableExportExcelEndpoint>{ type: SET_DATATABLE_EXPORT_EXCEL_ENDPOINT, payload: endPoint },
}
const unloadedState: State = { dataTableExportExcelEndpoint: "/api/excel/fromdatatable" }

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case "SET_DATATABLE_EXPORT_EXCEL_ENDPOINT":
            return { ...state, dataTableExportExcelEndpoint: action.payload }
        default:
    }
    return state || unloadedState
}