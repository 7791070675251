import * as React from 'react'
import styled from 'styled-components'
interface Props {
    onClose: () => void
    children: any
}
const PopupWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #ffffffaa;
    z-index: 999;
    text-align: center;
    overflow: auto;
`
const PopupContent = styled.div`
    max-width: 1200px;
    margin: auto;
    background-color: #ffffffee;
    position: relative;
    margin-top: 100px;
    margin-bottom: 50px;
    @media only screen and (max-width: 1200px) {
        margin: 0;
    }
`
const PopupClose = styled.i`
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    width: 50px;
    height: 50px;
    background: url(../img/ui/icon-close-cross.svg) no-repeat center;
    z-index: 1;
`
export const Popup = (props: Props) => {
    React.useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = ''
        }
    })
    return (
        <PopupWrapper>
            <PopupContent>
                <PopupClose onClick={props.onClose} />
                {props.children}
            </PopupContent>
        </PopupWrapper>
    )
}
