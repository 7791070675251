import * as React from 'react'
import FilterIcon from './FilterIcon'
import { FilterBody } from './FilterBody'
import { useOnCloseElement } from '../../Effects/useOnCloseElement'

interface Props {
    value: string
    onSubmit: (value: string) => void
}
interface State {
    value: string
    isOpen: boolean
}
const TextFilter = (props: Props) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [value, setValue] = React.useState('')

    const searchInput = React.useRef<HTMLInputElement>()
    // constructor(props: Props) {
    //     super(props)
    //     this.searchInput = React.createRef()
    // }
    React.useEffect(() => {
        if (props.value != value) {
            setValue(props.value)
        }
    }, [props.value])
    // componentWillReceiveProps(nextProps: Props) {
    //     if (this.state.value != nextProps.value) {
    //         this.setState({ value: nextProps.value })
    //     }
    // }
    // componentDidUpdate(prevProps: Props, prevState: State) {
    //     if (!prevState.isOpen && this.state.isOpen) {
    //         if (this.searchInput && this.searchInput.current) {
    //             this.searchInput.current.focus()
    //         }
    //     }
    // }
    React.useEffect(() => {
        if (isOpen && searchInput && searchInput && searchInput.current) {
            searchInput.current.focus()
            searchInput.current.select()
        }
    }, [isOpen])
    const close = () => {
        setIsOpen(false)
        setValue(props.value)
    }
    const clear = () => {
        //this.props.onSubmit("");
        setValue('')
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    const onSubmit = () => {
        props.onSubmit(value)
        close()
    }

    const onChange = (e: any) => {
        setValue(e.target.value)
    }
    const keyPressed = (event: any) => {
        switch (event.keyCode) {
            case 27:
                close()
                break
            case 13:
                onSubmit()
                break
            default:
                break
        }
    }
    return (
        <div ref={ref as any} className="data-table-filter">
            <FilterIcon
                title={props.value}
                isActive={props.value.length > 0}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen)
                    setValue(props.value)
                }}
            />
            {isOpen && (
                <FilterBody onClose={close}>
                    <i className="data-table-filter-clear" onClick={clear} />
                    <input ref={searchInput as any} value={value} onKeyDown={keyPressed} onChange={onChange} />
                    <button onClick={onSubmit} className="data-table-filter-add-button">
                        Submit
                    </button>
                    <div className="clear"></div>
                </FilterBody>
            )}
        </div>
    )
}
export default TextFilter
