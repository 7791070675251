import * as React from 'react'
import MultiSelectOption from './MultiSelectOption'
import { FilterBody } from './FilterBody'
import FilterIcon from './FilterIcon'
import { Scrollbars } from 'react-custom-scrollbars'
import { SelectOptionModel, SelectOptionValueType } from '../../Select/SelectOptionModel'
import { useOnCloseElement } from '../../Effects/useOnCloseElement'

type Props = {
    value: any[]
    options: MultiSelectOption[]
    onSubmit: (value: SelectOptionValueType[]) => void
}

const MultiSelectFilter = (props: Props) => {
    const searchInput = React.useRef() //: React.RefObject<HTMLInputElement>
    const [isOpen, setIsOpen] = React.useState(false)
    const [value, setValue] = React.useState([] as SelectOptionValueType[])
    const [search, setSearch] = React.useState('')
    React.useEffect(() => {
        if (value != props.value) {
            setValue(props.value)
        }
    }, [props.value])
    const close = () => {
        setIsOpen(false)
        setValue(props.value)
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    const clear = () => {
        setValue([])
    }
    const clearSearch = () => {
        setSearch('')
    }
    const selectAll = (options: SelectOptionModel[]) => {
        return () => {
            if (search && search.length > 0) {
                const ids = options.map((x) => x.value)
                setValue(ids)
            }
        }
    }
    const selectOption = (id: any) => {
        return () => {
            if (value.some((x) => x == id)) {
                setValue(value.filter((x) => x != id))
            } else {
                setValue(value.concat([id]))
            }
        }
    }

    const onSubmit = () => {
        props.onSubmit(value)
        close()
    }
    let options = props.options
    if (search && search.length > 0) {
        options = options.filter((x) => x.isParent || x.label.toLowerCase().indexOf(search) >= 0)
    }
    React.useEffect(() => {
        if (isOpen)
            if (searchInput && searchInput.current) {
                ;(searchInput as any).current.focus()
                ;(searchInput as any).current.select()
            }
    }, [isOpen])
    return (
        <div ref={ref as any} className="data-table-filter">
            <FilterIcon
                title={`${props.value.length} selected`}
                isActive={props.value.length > 0}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen)
                    setValue(props.value)
                }}
            />
            {isOpen && (
                <FilterBody onClose={close}>
                    {/* <i className="data-table-filter-close" onClick={close}></i> */}
                    <i className="data-table-filter-clear" onClick={clearSearch}></i>
                    <input
                        ref={searchInput as any}
                        value={search}
                        onChange={(e) => setSearch(e.target.value.toLowerCase())}
                    />
                    <div className="data-filter-actions">
                        {search && search.length > 0 && (
                            <span className="data-filter-action" onClick={selectAll(options)}>
                                Select All ({options.length})
                            </span>
                        )}
                        {value.length > 0 && (
                            <span className="data-filter-action float-right" onClick={clear}>
                                Clear Selection ({value.length})
                            </span>
                        )}
                    </div>
                    <Scrollbars style={{ height: 300 }} className="data-filter-multiselect-area">
                        {options.map((x, index) => {
                            return x.isParent ? (
                                <p key={`p-${index}`} className={`parent data-filter-multiselect-option`}>
                                    {x.label}
                                </p>
                            ) : (
                                <p
                                    key={x.value}
                                    className={`${x.isChild ? 'child ' : ''}data-filter-multiselect-option${
                                        value.some && value.some((v) => v == x.value) ? ' active' : ''
                                    }`}
                                    onClick={selectOption(x.value)}
                                >
                                    {x.label}
                                </p>
                            )
                        })}
                    </Scrollbars>
                    <button
                        onClick={() => {
                            onSubmit()
                        }}
                        className="data-table-filter-add-button"
                    >
                        Submit
                    </button>
                    <div className="clear"></div>
                </FilterBody>
            )}
        </div>
    )
}
export default MultiSelectFilter
