import * as React from 'react'
import { FormFieldWrapper } from '../Fields/FormFieldWrapper'
import { BaseFormFieldProps, FormControllerProps } from '../BaseProps'
import { FormFieldLabel } from '../Fields/FormFieldLabel'
import { FieldFooter } from '../FieldFooter'
import { Controller } from 'react-hook-form'
import { Editor } from '@tinymce/tinymce-react'
import 'react-quill/dist/quill.snow.css';
import ReactQuill from 'react-quill'
type WysiwygFieldProps = BaseFormFieldProps & {}
export const WysiwygFieldController = (props: WysiwygFieldProps & FormControllerProps) => {
    return (
        <Controller
            control={props.control}
            rules={props.rules}
            name={props.name || ''}
            render={({ field }) => (
                <WysiwygFormField
                    {...props}
                    value={field.value}
                    onChange={(e) => {
                        const value = e
                        field.onChange(value)
                        if (props.onChange) {
                            props.onChange(value)
                        }
                    }}
                />
            )}
        />
    )
}
export const WysiwygFormField = (props: WysiwygFieldProps) => {
    const onChange = (content: string) => {
        if (props.onChange) props.onChange(content)
    }
    // console.log(props.value)
    return (
        <FormFieldWrapper {...props}>
            {props.label && <FormFieldLabel {...props} />}
            <Editor
                onEditorChange={onChange}
                disabled={props.disabled}
                apiKey="sqk3an90c1t7cthhsq3omi8216oegxjb4lu4fpwhn704u4m1"
                // initialValue={props.defaultValue}
                value={props.disabled ? props.placeholder : props.value}
                init={{
                    height: 250,
                    menubar: false,
                    plugins: "paste, lists",
                    paste_as_text: true,
                    lists_indent_on_tab: false,
                    toolbar:
                        'undo redo | formatselect | bold italic | \
                      alignleft aligncenter alignright | \
                      bullist numlist',
                }}
            />
            {/* <ReactQuill
                onChange={onChange}
                readOnly={props.disabled}
                modules={}
                // initialValue={props.defaultValue}
                value={props.disabled ? props.placeholder : props.value}
            // init={{
            //     height: 250,
            //     menubar: false,
            //     toolbar:
            //         'undo redo | formatselect | bold italic | \
            //       alignleft aligncenter alignright | \
            //       bullist numlist outdent indent',
            // }}
            /> */}
            <FieldFooter error={props.error} errorDescription={props.errorDescription} />
        </FormFieldWrapper>
    )
}
