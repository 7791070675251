import * as React from 'react'
import MultiSelectFilter from './MultiSelectFilter'
import { DateFilter } from './DateFilter'
import TextFilter from './TextFilter'
import TextNumberFilter from './TextNumberFilter'
import SelectFilter from './SelectFilter'
import DateRangeFilter from './DateRangeFilter'
import { FilterType, FilterValueType } from './FilterTypes'

const filterFactory = (
    filterType: FilterType,
    value: any,
    onSubmit: (value: FilterValueType) => void,
    options: any,
) => {
    switch (filterType) {
        case FilterType.DATEPICKER:
            return <DateFilter value={value} onSubmit={(value) => onSubmit({ value, type: FilterType.DATEPICKER })} />
        case FilterType.DATERANGE:
            return (
                <DateRangeFilter
                    value={value}
                    onSubmit={(value: any) => onSubmit({ value, type: FilterType.DATERANGE })}
                />
            )
        case FilterType.SELECT:
            return (
                <SelectFilter
                    options={options}
                    value={value}
                    onSubmit={(value: any) => onSubmit({ value, type: FilterType.SELECT })}
                />
            )
        case FilterType.MULTISELECT:
            return (
                <MultiSelectFilter
                    options={options}
                    value={value || []}
                    onSubmit={(value: any) => onSubmit({ value, type: FilterType.MULTISELECT })}
                />
            )
        case FilterType.MULTISELECTNUMBER:
            return (
                <MultiSelectFilter
                    options={options}
                    value={value || []}
                    onSubmit={(value: any) => onSubmit({ value, type: FilterType.MULTISELECTNUMBER })}
                />
            )
        case FilterType.TEXT:
            return (
                <TextFilter value={value || ''} onSubmit={(value: any) => onSubmit({ value, type: FilterType.TEXT })} />
            )
        case FilterType.TEXTNUMBER:
            return (
                <TextNumberFilter
                    value={value || ''}
                    onSubmit={(value: any) => onSubmit({ value, type: FilterType.TEXTNUMBER })}
                />
            )
        // case FilterType.TEXTNUMBER:
        //     return <TextNumberFilter
        //         value = {(value && value.value) || ""}
        //         onSubmit = {(value) => onSubmit({value, type: FilterType.TEXTNUMBER})}
        //     />
        // case FilterType.MULTISELECT:
        //     return <MultiSelectFilter
        //         options = {options}
        //         value = {(value && value.value) || []}
        //         onSubmit = {(FilterValueType) => onSubmit({value, type: FilterType.MULTISELECT})}
        //     />
        default:
            return null
    }
}
export default filterFactory
// module.exports = filterFactory;
