import * as React from 'react'
import { BaseFormFieldErrorProps } from '../BaseProps'
interface Props extends BaseFormFieldErrorProps {
    children: any
    customWrapperClass?: string
}
export const FormFieldWrapper = (props: Props) => (
    <div className={`field-wrap${props.error ? ' is-error' : ''} ${props.customWrapperClass || ''}`}>
        {props.children}
    </div>
)
