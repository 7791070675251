export default class SignIn {
    email: string
    password: string
    returnUrl: string
    constructor(returnUrl: string) {
        this.email = ''
        this.password = ''
        this.returnUrl = returnUrl
    }
}
