import moment from 'moment'

export const sortAsc = (fieldName: string) => (a: any, b: any) =>
    a[fieldName] < b[fieldName] ? -1 : a[fieldName] > b[fieldName] ? 1 : 0
export const sortDesc = (fieldName: string) => (a: any, b: any) =>
    a[fieldName] > b[fieldName] ? -1 : a[fieldName] < b[fieldName] ? 1 : 0

export const dateMomentSortFunc = (type: string, fieldname: string) => (obj1: any, obj2: any) => {
    const date1 = obj1[fieldname]
    const date2 = obj2[fieldname]
    return date1 > date2
        ? type == 'ASC'
            ? 1
            : -1
        : date1 == date2
        ? obj1.Id > obj2.Id
            ? 1
            : -1
        : type == 'ASC'
        ? -1
        : 1
}
export const dateStringSortFunc = (type: string, fieldName: string) => (obj1: any, obj2: any) => {
    const date1 = moment(obj1[fieldName], 'DD.MM.YYYY')
    const date2 = moment(obj2[fieldName], 'DD.MM.YYYY')
    return date1 > date2
        ? type == 'ASC'
            ? 1
            : -1
        : date1 == date2
        ? obj1.Id > obj2.Id
            ? 1
            : -1
        : type == 'ASC'
        ? -1
        : 1
}
export const entityMapSortFunc =
    (data: Map<any, any>) => (type: string, fieldName: string) => (obj1: any, obj2: any) => {
        return data.get(obj1.EntityId) > data.get(obj2.EntityId)
            ? type == 'ASC'
                ? 1
                : -1
            : obj1.EntityId == obj2.EntityId
            ? obj1.Id > obj2.Id
                ? 1
                : -1
            : type == 'ASC'
            ? -1
            : 1
    }

export const numberSortFunc = (type: string, fieldName: string) => (obj1: any, obj2: any) => {
    const res =
        obj1[fieldName] > obj2[fieldName]
            ? type == 'ASC'
                ? 1
                : -1
            : obj1[fieldName] < obj2[fieldName]
            ? type == 'ASC'
                ? -1
                : 1
            : obj1.id > obj2.id
            ? 1
            : obj1.id == obj2.id
            ? 0
            : -1
    return res
}
