import * as React from 'react'
import { MediaDisplay } from '../MediaManager/MediaDisplay'
import { MediaManager } from './MediaManager'
import { Photo } from '..'
import { ApplicationState } from '../store'
import { useSelector } from 'react-redux'

interface Props {
    selected: Photo[]
    heroImageId?: number
    onSelectPhotos?: (photos: Photo[]) => void
    onHeroImageChanged?: (photo: Photo) => void
    onDelete?: (photo: Photo) => void
}
export const MediaSelector = (props: Props) => {
    const [showMediaManager, setShowMediaManager] = React.useState(false)
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const onRemove = (p: Photo) => {
        if (props.onDelete) {
            props.onDelete(p)
        } else {
            if (props.onSelectPhotos) {
                props.onSelectPhotos(props.selected.filter((x) => x.id != p.id))
            }
        }
    }
    return (
        <div className="field-wrap">
            {showMediaManager && (
                <MediaManager
                    onSelectPhotos={props.onSelectPhotos}
                    onClose={() => setShowMediaManager(false)}
                    selected={props.selected}
                />
            )}
            <label>{Localization.getString('Photos')}</label>
            <div className="drop-field-wrap">
                {/* <div className="drop-field-caption"></div> */}
                {/* <a
                    className="drop-field-button"
                    onClick={() => {
                        setShowMediaManager(true)
                    }}
                >
                    {Localization.getString('Select Photos')}
                </a> */}

                <div className="drop-field-uploaded-wrap">
                    <ul className="drop-field-uploaded">
                        {props.selected.map((p) => (
                            <MediaDisplay
                                key={p.id}
                                type="Hero"
                                photo={p}
                                isSelected={p.id == props.heroImageId}
                                onSelect={props.onHeroImageChanged}
                                onDelete={() => onRemove(p)}
                            />
                        ))}
                        <li
                            onClick={() => {
                                setShowMediaManager(true)
                            }}
                            className="uploaded-item uploaded-item-add-wrap"
                        >
                            <a className="uploaded-item-add">
                                <i></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
