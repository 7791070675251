import { Action, Reducer } from 'redux'
import { apiCallWrapper } from '../services/apiCallWrapper'
import SignIn from '../serviceModels/SignIn'
import Token from '../serviceModels/Token'
import { AppThunkAction } from '.'
import { getParsedToken, getToken } from '../Utils/authUtils'
import { IChangePassword } from '../serviceModels/ChangePassword'
import { push } from 'react-router-redux'
import { AuthData } from '../models/AuthData'
import Cookies from 'universal-cookie'
const getAuthData = () => {
    return getParsedToken()
}
export interface State {
    isAuthorized: boolean
    signInError: string
    authData?: AuthData
}

export interface AuthUser {
    type: 'AUTH_USER'
    token: string
}

interface UnAuthUser {
    type: 'UNAUTH_USER'
}
const SIGNIN_ERROR = 'SIGNIN_ERROR'
interface SignInError {
    type: 'SIGNIN_ERROR'
    error: string
}
const SIGNIN_SUCCESS = 'SIGNIN_SUCCESS'
interface SignInSuccess {
    type: 'SIGNIN_SUCCESS'
}
// const UPDATE_SIGNIN_CREDENTIALS = 'UPDATE_SIGNIN_CREDENTIALS'
// interface UpdateSignInCredentials {
//     type: 'UPDATE_SIGNIN_CREDENTIALS'
//     payload: SignIn
// }
export const AUTH_CHANGEPASSWORD_ERROR = 'AUTH_CHANGEPASSWORD_ERROR'
export interface AuthChangePasswordError {
    type: 'AUTH_CHANGEPASSWORD_ERROR'
    error: string
}
export const AUTH_CHANGEPASSWORD_SUCCESS = 'AUTH_CHANGEPASSWORD_SUCCESS'
export interface AuthChangePasswordSuccess {
    type: 'AUTH_CHANGEPASSWORD_SUCCESS'
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction =
    | AuthUser
    | UnAuthUser
    | SignInSuccess
    | SignInError
    // | UpdateSignInCredentials
    | AuthChangePasswordError
    | AuthChangePasswordSuccess

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    changePassword:
        (changePassword: IChangePassword): AppThunkAction<KnownAction> =>
            async (dispatch) => {
                const changePasswordJson = JSON.stringify({
                    password: changePassword.password,
                    passwordRepeat: changePassword.passwordRepeat,
                    currentPassword: changePassword.currentPassword,
                })
                const result = await apiCallWrapper<boolean>(`api/auth/changepassword`, 'PUT', dispatch, changePasswordJson)
                if (result.IsOk) {
                    dispatch({ type: AUTH_CHANGEPASSWORD_SUCCESS })
                } else {
                    dispatch({ type: AUTH_CHANGEPASSWORD_ERROR, error: result.ErrorMessage })
                }
            },
    signInError: (error: string) => <SignInError>{ type: SIGNIN_ERROR, error },
    authorizeUser: (token: string) => <AuthUser>{ type: 'AUTH_USER', token },
    unAuthorizeUser: () => <UnAuthUser>{ type: 'UNAUTH_USER' },
    // updateSignInCredentials: (signIn: SignIn) =>
    //     <UpdateSignInCredentials>{ type: UPDATE_SIGNIN_CREDENTIALS, payload: signIn },
    signInSuccess: (token: Token) => <SignInSuccess>{ type: SIGNIN_SUCCESS, token },
    // refreshToken: (): AppThunkAction<KnownAction> => (dispatch) => {},
    logMeIn:
        (email: string): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                const result = await apiCallWrapper<Token>(`api/auth/logmein?email=${email}`, 'GET', dispatch)
                if (result.IsOk) {
                    localStorage.clear()
                    const token = result.Content ? result.Content.token : ''
                    dispatch({ type: 'AUTH_USER', token })
                    dispatch({ type: SIGNIN_SUCCESS })
                    dispatch(push('/') as any)
                }
            },
    signIn:
        (signIn: SignIn): AppThunkAction<KnownAction> =>
            async (dispatch, getState) => {
                const Localization = getState().global.locale
                // dispatch({ type: UPDATE_SIGNIN_CREDENTIALS, payload: signIn })
                const result = await apiCallWrapper<Token>('api/auth/signin', 'POST', dispatch, signIn)
                if (!result.IsOk) {
                    dispatch({ type: SIGNIN_ERROR, error: Localization.getString('Wrong email of password') })
                } else {
                    if (result.IsOk) {
                        localStorage.clear()
                        const token = result.Content ? result.Content.token : ''
                        dispatch({ type: 'AUTH_USER', token })
                        dispatch({ type: SIGNIN_SUCCESS })
                        dispatch(push(signIn.returnUrl) as any)
                    }
                }
            },
    signOut:
        (returnUrl: string = '/'): AppThunkAction<KnownAction> =>
            (dispatch) => {
                dispatch({ type: 'UNAUTH_USER' })
                dispatch(push(returnUrl) as any)
                localStorage.clear()
            },
}
const unloadedState: State = { isAuthorized: getToken() != null, authData: getAuthData(), signInError: '' }

export const reducer: Reducer<State> = (state: State = unloadedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction
    switch (action.type) {
        case 'AUTH_USER':
            if (typeof localStorage !== 'undefined') {
                localStorage.setItem('token', action.token)
                const cookies = new Cookies()
                cookies.remove('AuthToken')
                cookies.set('AuthToken', action.token, { path: '/' })
            }
            const authData = getAuthData()
            console.log(authData)
            return { ...state, isAuthorized: true, authData }
        case 'UNAUTH_USER':
            localStorage.removeItem('token')
            const cookies = new Cookies()
            cookies.remove('AuthToken')
            return { ...state, isAuthorized: false, authData: undefined }
        case SIGNIN_SUCCESS:
            return { ...state, signInError: '', signIn: new SignIn('/') }
        case SIGNIN_ERROR:
            return { ...state, signInError: action.error }
        // case UPDATE_SIGNIN_CREDENTIALS:
        //     return { ...state, signIn: action.payload }
        case AUTH_CHANGEPASSWORD_ERROR:
        case AUTH_CHANGEPASSWORD_SUCCESS:
            return state
        default:
    }

    return state || unloadedState
}
