import { sortAsc } from './sorts'

export type SyncableData = {
    id: number | string
    name: string
    isDeleted: boolean
}
export const syncDataLists = (currentList: SyncableData[], updates: SyncableData[]) => {
    currentList = currentList.map((x) => updates.find((z) => z.id == x.id) || x)
    currentList = currentList.filter((x) => !x.isDeleted)
    currentList = currentList.concat(updates.filter((x) => !x.isDeleted && !currentList.some((z) => z.id == x.id)))
    currentList = currentList
        .reduce((res, next) => {
            if (!res.some((x) => x.id == next.id)) {
                res.push(next)
            }
            return res
        }, [] as SyncableData[])
        .sort(sortAsc('Name'))
    return currentList
}
