import * as React from 'react'
import * as MediaStore from '../store/Media'
import { useDispatch, useSelector } from 'react-redux'
import MediaEditPopup from './MediaEditPopup'
import { Photo } from '../models/Photo'
import { ApplicationState } from '../store'
import { Tooltip } from '../Tooltip'

interface Props {
    photo: Photo
    type?: MediaDisplayType
    isSelected?: boolean
    hideEdit?: boolean
    onDelete?: (photo: Photo) => void
    onSelect?: (photo: Photo) => void
}
type MediaDisplayType = 'Select' | 'Hero'

const TypeConstants = {
    Hero: {
        Selected: 'Main photo',
        Select: 'Set as main photo',
    },
    Select: {
        Selected: 'Selected',
        Select: 'Select photo',
    },
}
export const MediaDisplay = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)

    const p = props.photo
    const onEdit = () => {
        dispatch(MediaStore.actionCreators.edit(p))
    }
    const onDelete = () => {
        if (props.onDelete) {
            props.onDelete(props.photo)
        } else {
            dispatch(MediaStore.actionCreators.delete(p))
        }
    }
    const onHeroImageChanged = () => {
        if (props.onSelect) props.onSelect(p)
    }
    const str = TypeConstants[props.type || 'Select']
    return (
        <li key={p.id} className="uploaded-item">
            <MediaEditPopup photo={p} />
            <img src={p.urlSmall} alt={p.title} />
            <div className="uploaded-item-tools">
                {props.onSelect && (
                    <Tooltip
                        style={props.isSelected ? undefined : { width: 140 }}
                        text={
                            props.isSelected ? Localization.getString(str.Selected) : Localization.getString(str.Select)
                        }
                    >
                        <a
                            onClick={onHeroImageChanged}
                            className={`uploaded-item-tools--main${props.isSelected ? ' is-active' : ''}`}
                        ></a>
                    </Tooltip>
                )}
                {!props.hideEdit && (
                    <Tooltip text={Localization.getString('Edit')}>
                        <a onClick={onEdit} className="uploaded-item-tools--edit"></a>
                    </Tooltip>
                )}
                <Tooltip text={Localization.getString('Delete')}>
                    <a onClick={onDelete} className="uploaded-item-tools--remove"></a>
                </Tooltip>
            </div>
        </li>
    )
}
