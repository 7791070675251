import * as React from 'react'
interface Props {
    children: any
    isFullScreen?: boolean
    style?: React.CSSProperties
}
export const Container = (props: Props) => {
    return (
        <div style={props.style} className={props.isFullScreen ? '' : 'container'}>
            {props.children}
        </div>
    )
}
export const ContainerWithPadding = (props: Props) => {
    return (
        <div className="container">
            <div className="content-wrap content-wrap-padding">{props.children}</div>
        </div>
    )
}
