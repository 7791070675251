import * as React from 'react'
export const ToTop = () => {
    const [isShown, setIsShown] = React.useState(false)
    const onScroll = () => {
        if (window.scrollY > 100 && !isShown) {
            setIsShown(true)
        } else {
            setIsShown(false)
        }
    }
    const toTop = () => {
        ScrollToTop()
    }
    React.useEffect(() => {
        window.addEventListener('scroll', onScroll)
        return () => {
            window.removeEventListener('scroll', onScroll)
        }
    }, [])
    return <a onClick={toTop} className={`back-to-top${isShown ? ' is-shown' : ''}`} data-bottom="56"></a>
}
export const ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}
