import * as React from 'react'
import { FormFieldLabel } from './FormFieldLabel'
import { BaseFormFieldProps } from '../BaseProps'
import { FormFieldWrapper } from './FormFieldWrapper'
import styled from 'styled-components'
interface Props extends BaseFormFieldProps {}
const Label = styled.div`
    padding: 15px 15px 15px 15px;
    border-radius: 5px;
    border: 1px solid #e6e6e6;
    /* background: #fbfbfb; */
    color: #1b214a;
`
export const LabelFormField = (props: Props) => {
    return (
        <FormFieldWrapper>
            <FormFieldLabel {...props} />
            <Label>{props.value}</Label>
        </FormFieldWrapper>
    )
}
