import * as React from 'react'
import InputBaseProps from './InputBaseProps'
interface InputCheckboxProps extends InputBaseProps {}

const InputCheckbox = (props: InputCheckboxProps) => {
    const onChange = () => {
        props.onChange(!props.value)
    }
    return (
        <div className="datatable-checkbox-wrapper" onClick={onChange}>
            {props.value && (
                <i title={props.placeholder} className={`clickable fa fa-check active":""}`} onClick={onChange} />
            )}
        </div>
    )
}
export default InputCheckbox
