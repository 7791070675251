import * as React from 'react'
import { Tooltip } from '../../Tooltip'
import { BaseFormFieldProps } from '../BaseProps'
export const FormFieldLabel = (props: BaseFormFieldProps) => {
    return (
        <>
            {(props.toolTip || props.toolTipHtml) && (
                <Tooltip
                    style={{ width: 300 }}
                    className="form-tooltip"
                    text={props.toolTip}
                    richText={props.toolTipHtml}
                >
                    <i className="fa fa-question-circle" />
                </Tooltip>
            )}
            <label htmlFor={props.id}>
                {props.label} {props.isRequired && <span>*</span>}
            </label>
            {props.subLabel && <label className="sublabel">{props.subLabel}</label>}
        </>
    )
}
