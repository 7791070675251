export const capitalize = (val: string) => {
    if (val && val.length > 0) {
        if (val.length > 1) {
            return val.charAt(0).toUpperCase() + val.slice(1)
        } else {
            return val.charAt(0).toUpperCase()
        }
    }
    return val
}
