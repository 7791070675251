export class CurrencyConverter {
    static convert(value: number, rate: number, type: ConversionType) {
        return type == ConversionType.MULTI ? value * rate : value / rate
    }
    static convertBack(value: number, rate: number, type: ConversionType) {
        return type == ConversionType.DEV ? value * rate : value / rate
    }
}
export enum ConversionType {
    MULTI, DEV
}