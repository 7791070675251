import * as React from 'react'

import DayPicker from 'react-day-picker/DayPicker'
import { FilterDateRangeType } from './FilterTypes'
import FilterIcon from './FilterIcon'
import { FilterBody } from './FilterBody'
import { DateUtils } from 'react-day-picker'
import moment from 'moment'
import { useOnCloseElement } from '../../Effects/useOnCloseElement'

interface Props {
    value: FilterDateRangeType
    onSubmit: (value?: FilterDateRangeType) => void
}

const DateRangeFilter = (props: Props) => {
    const [isOpen, setIsOpen] = React.useState(false)
    const [value, setValue] = React.useState(props.value)

    React.useEffect(() => {
        setValue(props.value)
    }, [props.value])

    const close = () => {
        setIsOpen(false)
        setValue(props.value)
    }
    const ref = React.useRef()
    useOnCloseElement(ref, close)
    const clear = () => {
        props.onSubmit(undefined)
        close()
    }

    const onSubmit = () => {
        props.onSubmit(value)
        close()
    }
    const from = value && value.startDate ? value.startDate.toDate() : undefined
    const to = value && value.endDate ? value.endDate.toDate() : undefined
    const range = { from, to }
    const modifiers = { start: from, end: to }
    return (
        <div ref={ref as any} className="data-table-filter">
            <FilterIcon
                title={props.value ? props.value.toString() : ''}
                isActive={props.value ? true : false}
                isOpen={isOpen}
                onClick={() => {
                    setIsOpen(!isOpen)
                    setValue(props.value)
                }}
            />
            {isOpen && (
                <FilterBody onClose={close} width="auto">
                    <DayPicker
                        initialMonth={from}
                        numberOfMonths={1}
                        selectedDays={[from, { from, to } as any]}
                        modifiers={modifiers}
                        onDayClick={(e) => {
                            const newRange = DateUtils.addDayToRange(e, range as any)
                            setValue(
                                FilterDateRangeType.Create(
                                    moment.utc(newRange.from).startOf('day'),
                                    moment.utc(newRange.to).startOf('day'),
                                ),
                            )
                        }}
                    />

                    <button onClick={onSubmit} className="data-table-filter-add-button">
                        Submit
                    </button>
                    {props.value && (
                        <button onClick={clear} className="data-filter-action" style={{ width: '100%', marginTop: 10 }}>
                            Clear
                        </button>
                    )}
                    <div className="clear"></div>
                </FilterBody>
            )}
        </div>
    )
}
export default DateRangeFilter
