export class MapsLocation {

    constructor(address: string, lat: number | undefined, lng: number | undefined) {
        this.lat = lat
        this.lng = lng
        this.address = address
    }
    lat?: number = 0;
    lng?: number = 0;
    address: string = "";
}