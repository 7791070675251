import * as React from 'react'
import { BaseFormFieldProps, FormControllerProps } from './BaseProps'
import { TextInput, NumberInput } from './Inputs'
import { FormFieldLabel } from './Fields/FormFieldLabel'
import { FieldFooter } from './FieldFooter'
import moment from 'moment'
import { Select, SelectProps } from '../Select/Select'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import { Modifier } from 'react-day-picker/types/Modifiers'
import { Controller } from 'react-hook-form'
import { FormFieldWrapper } from './Fields/FormFieldWrapper'
import { TimePicker, TimePickerProps } from '../TimePicker'
import { Tooltip } from '../Tooltip'
import { SelectOptionModel } from '../Select/SelectOptionModel'
import styled from 'styled-components'
export const FormFieldHidable = (props: { hidden: boolean; children: any }) => {
    return <div className={props.hidden ? 'hidden' : ''}>{props.children}</div>
}

export type DateFormFieldProps = BaseFormFieldProps & {
    disabledDays?: Modifier | Modifier[]
    initialMonth?: Date
    value?: moment.Moment
}
export const DateFormField = (props: DateFormFieldProps) => (
    <FormFieldWrapper {...props}>
        {props.label && <FormFieldLabel {...props} />}
        <DayPickerInput
            value={props.value?.format('DD.MM.YYYY') ?? props.defaultValue?.format('DD.MM.YYYY')}
            classNames={{ container: 'field-wrap field-wrap-date', overlay: '', overlayWrapper: '' }}
            format="DD.MM.YYYY"
            placeholder={props.placeholder || 'DD.MM.YYYY'}
            onDayChange={props.onChange}
            dayPickerProps={{
                initialMonth: props.initialMonth,
                disabledDays: props.disabledDays,
                selectedDays: props.value?.toDate() ?? props.defaultValue?.toDate(),
            }}
        />
        <FieldFooter error={props.error} errorDescription={props.errorDescription} />
    </FormFieldWrapper>
)

export type DateFormFieldPropsController = DateFormFieldProps & FormControllerProps
export const DateFormFieldController = (props: DateFormFieldPropsController) => (
    <Controller
        rules={props.rules}
        control={props.control}
        name={props.name || ''}
        render={({ field }) => (
            <DateFormField
                {...props}
                value={field.value}
                onChange={(e) => {
                    const value = e ? moment(e) : e // parseFloat(e.target.value) || 0
                    field.onChange(value)
                    if (props.onChange) {
                        props.onChange(value)
                    }
                }}
            />
        )}
    />
)

export type TimePickerFormFieldProps = FormControllerProps & BaseFormFieldProps
export const TimePickerFormField = (props: TimePickerProps & BaseFormFieldProps) => {
    return (
        <FormFieldWrapper {...props}>
            {props.label && <FormFieldLabel {...props} />}
            <TimePicker {...props} />
            <FieldFooter error={props.error} errorDescription={props.errorDescription} />
        </FormFieldWrapper>
    )
}
export const TimePickerFormFieldController = (props: TimePickerFormFieldProps) => {
    return (
        <Controller
            rules={props.rules}
            control={props.control}
            defaultValue={props.defaultValue}
            name={props.name || ''}
            render={({ field }) => (
                <TimePickerFormField
                    {...props}
                    onChange={(e: string) => {
                        field.onChange(e)
                        if (props.onChange) {
                            props.onChange(e)
                        }
                    }}
                    id={props.id}
                    value={field.value}
                />
            )}
        />
    )
}

export const ToggleFormField = (props: BaseFormFieldProps) => {
    const [value, setValue] = React.useState(false)
    React.useEffect(() => {
        setValue(props.value || props.defaultValue)
    }, [props.value, props.defaultValue])
    const onClick = () => {
        setValue(!value)
        if (props.onChange) props.onChange(!value)
    }
    return (
        <div className="field-wrap">
            <label>{props.label}</label>
            <div className={`custom-checkbox-wrap is-toggler-style${value ? ' is-checked' : ''}`}>
                <span onClick={onClick}></span>
            </div>
        </div>
    )
}
export const ToggleFieldController = (props: BaseFormFieldProps & FormControllerProps) => (
    <Controller
        rules={props.rules}
        control={props.control}
        name={props.name || ''}
        defaultValue={props.defaultValue}
        // onChange={([e]: any) => {
        //     if (props.onChange) {
        //         props.onChange(e)
        //     }
        //     return e
        // }}
        render={({ field }) => (
            <ToggleFormField
                {...props}
                value={field.value}
                onChange={(e: boolean) => {
                    field.onChange(e)
                    if (props.onChange) {
                        props.onChange(e)
                    }
                }}
            />
        )}
    />
)
type SelectFormFieldProps = BaseFormFieldProps & SelectProps
export const SelectFormFieldController = (props: SelectFormFieldProps & FormControllerProps) => (
    <Controller
        rules={props.rules}
        name={props.name || ''}
        render={({ field }) => (
            <SelectFormField
                {...props}
                onChangeMulti={(e: SelectOptionModel[]) => {
                    const value = e ? e.map((x) => x.value) : []
                    field.onChange(value)
                    if (props.onChange) {
                        props.onChange(value)
                    }
                }}
                onChange={(e: SelectOptionModel) => {
                    field.onChange(e.value)
                    if (props.onChange) {
                        props.onChange(e)
                    }
                }}
                value={field.value}
            />
        )}
        control={props.control}
    />
)
const ClearSelect = styled.i`
    float: right;
    cursor: pointer;
`
export const SelectFormField = (props: SelectFormFieldProps) => {
    return (
        <div className={`field-wrap${props.error ? ' is-error' : ''}`}>
            {props.isClearable ? <ClearSelect className="fa fa-trash" onClick={props.onClear} /> : null}
            {props.label && <FormFieldLabel {...props} />}
            <Select {...props} />
            <FieldFooter error={props.error} errorDescription={props.errorDescription} />
        </div>
    )
}
