import * as React from 'react'
import { PaginationModel } from './models/PaginationModel'
import { Link } from 'react-router-dom'
interface PaginationProps {
    onPageChange?: (page: number) => void
    pagination: PaginationModel
    refFunc?: (ref: HTMLDivElement) => void
    hrefPrefix?: string
    showSummary?: boolean
}
export class Pagination extends React.Component<PaginationProps, {}> {
    private selfRef: HTMLDivElement
    constructor(props: PaginationProps) {
        super(props)
        this.selfRef = null as any
    }
    setRef = (element: HTMLDivElement) => {
        this.selfRef = element
        if (this.props.refFunc) this.props.refFunc(element)
    }
    render() {
        const p = this.props.pagination
        return p && p.totalPages > 1 ? (
            <div className="data-table-pagination" ref={this.setRef}>
                <span className={`data-table-pagination-summary${this.props.showSummary ? ' visible' : ''}`}>
                    {(this.props.pagination.currentPage - 1) * this.props.pagination.itemsPerPage + 1}-
                    {this.props.pagination.currentPage * this.props.pagination.itemsPerPage}/
                    {this.props.pagination.totalItems}
                </span>
                <PaginationItem
                    onClick={this.props.onPageChange}
                    page={p.prevPage}
                    href={this.props.hrefPrefix}
                    className="prevposts-link clickable"
                >
                    <i className="fa fa-caret-left"></i>
                </PaginationItem>
                {p.pagesArray().map((page, index) => {
                    return page == -1 ? (
                        <a key={page + '-' + index}>...</a>
                    ) : (
                        <PaginationItem
                            className={`clickable data-table-page${page == p.currentPage ? ' current-page' : ''}`}
                            onClick={this.props.onPageChange}
                            key={page}
                            page={page}
                            href={this.props.hrefPrefix}
                        >
                            {page}
                        </PaginationItem>
                    )
                })}
                <PaginationItem
                    onClick={this.props.onPageChange}
                    href={this.props.hrefPrefix}
                    className="nextposts-link clickable"
                    page={p.nextPage}
                >
                    <i className="fa fa-caret-right"></i>
                </PaginationItem>
            </div>
        ) : null
    }
}

interface PaginationItemProps {
    href?: string
    page: number
    onClick?: (page: number) => void
    children: any
    className: string
}
class PaginationItem extends React.Component<PaginationItemProps, {}> {
    onClick = () => {
        if (this.props.onClick) this.props.onClick(this.props.page)
    }
    render() {
        return this.props.href ? (
            <Link to={`${this.props.href}${this.props.page}`} className={this.props.className}>
                {this.props.children}
            </Link>
        ) : (
            <a onClick={this.onClick} className={this.props.className}>
                {this.props.children}
                {/* <i className="fa fa-caret-left"></i> */}
            </a>
        )
    }
}
