import * as React from 'react'
import InputNumber from './InputNumber'
import InputText from './InputText'
import { InputType } from './InputType'
import InputCheckbox from './InputCheckbox'
import DayPickerInput from 'react-day-picker/DayPickerInput'
import moment from 'moment'
import { SelectOptionModel } from '../../Select/SelectOptionModel'
import { minutesToTimeString, TimePicker, timeStringToMinutes } from '../../TimePicker'
import { Select } from '../../Select/Select'
interface InputFactoryProps {
    value?: any
    step?: number
    isClearable?: boolean
    type: InputType
    onChange: (value: any) => void
    options?: SelectOptionModel[]
    placeholder: string
    // parameters: any
}
const InputFactory = (props: InputFactoryProps) => {
    const onChangeSelect = (value: any) => {
        props.onChange(value.value)
    }
    const onClearSelect = () => {
        props.onChange(undefined)
    }
    const onChangeTime = (value: string) => {
        props.onChange(timeStringToMinutes(value))
    }
    const onDayChange = (value: any) => {
        const day = moment.utc(value).startOf('d')
        props.onChange(day)
    }
    switch (props.type) {
        case InputType.Checkbox:
            return <InputCheckbox placeholder="Is Active" value={props.value} onChange={props.onChange} />
        case InputType.Number:
            return (
                <InputNumber
                    placeholder={props.placeholder}
                    onChange={props.onChange}
                    value={props.value}
                    min={0}
                    max={9999}
                    step={props.step || 1}
                />
            )
        case InputType.Select:
            return (
                <Select
                    options={props.options || []}
                    isClearable={props.isClearable}
                    onClear={onClearSelect}
                    value={props.value}
                    onChange={onChangeSelect}
                />
            )
        case InputType.Date:
            return (
                <DayPickerInput
                    placeholder="DD.MM.YYYY"
                    format="DD.MM.YYYY"
                    value={props.value.format('DD.MM.YYYY')}
                    onDayChange={onDayChange}
                />
            )
        case InputType.Time:
            // return <div></div>
            return <TimePicker value={minutesToTimeString(props.value as number)} onChange={onChangeTime} />
        case InputType.Text:
        default:
            return <InputText placeholder={props.placeholder} value={props.value} onChange={props.onChange} />
    }
}
export default InputFactory
