import * as React from 'react'
const minTitleLen = 50
const maxTitleLen = 70
const maxDescrLen = 155
export const SeoLen = (props: { title: string, type: "Title" | "Description" | "Other" }) => {
    if (props.type == 'Other') {
        return <p className='seo-len'>Length: {props.title?.length ?? 0}</p>
    }
    const minLen = props.type == 'Title' ? minTitleLen : 1
    const maxLen = props.type == 'Title' ? maxTitleLen : maxDescrLen
    const isOk = props.title && props.title.length > minLen && props.title.length < maxLen
    return <p title={`Between ${minLen} to ${maxLen}`} className={`seo-len ${isOk ? "success" : "error"}`}>Length: {props.title?.length ?? 0}</p>
}