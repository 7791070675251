import * as React from 'react'
import InputBaseProps from './InputBaseProps'
interface InputNumberProps extends InputBaseProps {}
const InputText = (props: InputNumberProps) => {
    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange(e.target.value)
    }
    return (
        <input
            style={{ width: '100%' }}
            type="text"
            placeholder={props.placeholder}
            value={props.value || ''}
            onChange={onChange}
        />
    )
}
export default InputText
