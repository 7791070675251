import * as React from 'react'
import { Autocomplete, GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api';
import { MapsLocation } from './MapsLocation';

const containerStyle = {
    width: '100%',
    height: '400px'
};
interface Props {
    apiKey: string
    value: MapsLocation
    onChange: (value: MapsLocation) => void
}
let autocomplete: google.maps.places.Autocomplete | undefined
const libraries: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["places"];
export const MapsLocationPicker = (props: Props) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: props.apiKey,
        libraries: libraries
    })
    const onLoadAutocomplete = (autocompleteParam: google.maps.places.Autocomplete) => {
        autocomplete = autocompleteParam
    }
    const [userLocation, setUserLocation] = React.useState({ lat: 25.187440546619328, lng: 55.28278819138045 })
    const [map, setMap] = React.useState<google.maps.Map | null>(null)
    const setUserLocationFromNavigator = (position: any) => {
        setUserLocation({ lat: position.coords.latitude, lng: position.coords.longitude })
        if (window.google && window.google.maps) {
            const center = new window.google.maps.LatLng({ lat: userLocation.lat, lng: userLocation.lng });
            if (map)
                map.setCenter(center);
        }
    }
    React.useEffect(() => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(setUserLocationFromNavigator);
        }
    }, [])
    React.useEffect(() => {

    }, [props.apiKey])



    const onLoad = React.useCallback(function callback(map: google.maps.Map) {
        const center = (props.value && props.value.lat && props.value.lng)
            ? new window.google.maps.LatLng({ lat: props.value.lat, lng: props.value.lng })
            : new window.google.maps.LatLng(userLocation);
        map.setCenter(center);
        setMap(map)
    }, [userLocation])
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    const onClickMap = (e: google.maps.MapMouseEvent) => {
        props.onChange({ address: props.value.address, lat: e.latLng?.lat(), lng: e.latLng?.lng() })
    }
    const onAddressChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
        props.onChange({ ...props.value, address: e.target.value })
    }

    const onDropLocation = () => {
        props.onChange({ lat: undefined, lng: undefined, address: '' })
    }
    const onPlaceChanged = () => {
        if (autocomplete) {
            const place = autocomplete.getPlace()
            if (place && place.geometry && place.geometry.location) {
                const center = new window.google.maps.LatLng({ lat: place.geometry.location.lat(), lng: place.geometry.location.lng() });
                map?.setCenter(center);
                props.onChange({ address: place.formatted_address ?? "", lat: place.geometry?.location.lat(), lng: place.geometry?.location.lng() })
            }
            else {
                alert("Could not retreive place information")
            }
        }
    }
    return <>
        {isLoaded ?
            <>
                <label>Address</label>
                <Autocomplete
                    onLoad={onLoadAutocomplete}
                    onPlaceChanged={onPlaceChanged}
                >
                    <input
                        type="text"
                        placeholder="Enter or search for address..."
                        onChange={onAddressChanged}
                        value={props.value.address}
                    />
                </Autocomplete>
                <br />
            </>
            : null}
        {isLoaded
            ? <GoogleMap
                mapContainerStyle={containerStyle}
                center={userLocation}
                zoom={12}
                onLoad={onLoad}
                onUnmount={onUnmount}
                onClick={onClickMap}
            >
                {props.value.lat && props.value.lng
                    ? <Marker position={new google.maps.LatLng(props.value.lat, props.value.lng)} />
                    : null}

            </GoogleMap>
            : null}
        {props.value.lat && <label className="clickable" onClick={onDropLocation}>Clear location</label>}
    </>
}
