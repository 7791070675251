import * as React from 'react'
import { DataTableColumnModel } from './DataTableColumnModel'
type CustomEditAction = (fieldName: string, obj: any) => void
interface DataTableModelParameters {
    columns: DataTableColumnModel[]
    name: string
    fileName?: string
    showSum?: boolean
    customActions?: DataTableModelCustomAction[]
    customEditAction?: CustomEditAction
    customToolBarComponents?: (() => any)[]
    headerGroups?: DataHeaderGroup[][]
    isObjectEditableClause?: (obj: any) => string | undefined
    isObjectDeletableClause?: (obj: any) => string | undefined
    localizator?: (key: string) => string
}
export class DataTableModel {
    constructor(params: DataTableModelParameters) {
        this.name = params.name
        this.fileName = params.fileName || 'download'
        this.columns = params.columns
        this.showSum = params.showSum ?? false
        this.customActions = params.customActions || ([] as DataTableModelCustomAction[])
        this.customEditAction = params.customEditAction
        this.customToolBarComponents = params.customToolBarComponents
        this.headerGroups = params.headerGroups || []
        this.isObjectEditableClause = params.isObjectEditableClause
        this.isObjectDeletableClause = params.isObjectDeletableClause
        this.localizator = params.localizator
    }
    name: string
    fileName: string
    columns: DataTableColumnModel[]
    showSum: boolean
    localizator?: (key: string) => string
    headerGroups: DataHeaderGroup[][]
    customToolBarComponents?: (() => React.Component)[]
    customActions: DataTableModelCustomAction[]
    setFilter(filter: any) {
        this.columns = this.columns.map((x) => {
            const valWrapper = filter.get(x.fieldName)
            if (valWrapper) {
                const val = valWrapper.value
                x.filterValue = val
            }
            return x
        })
    }
    customEditAction?: CustomEditAction
    appendRows?: React.ReactNode
    isObjectEditableClause?: (obj: any) => string | undefined
    isObjectDeletableClause?: (obj: any) => string | undefined
}
export interface DataHeaderGroup {
    Title: string
    ColSpan: number
}
export class DataTableModelCustomAction {
    constructor(onClick: () => void, renderer: any, title = '') {
        this.onClick = onClick
        if (!this.onClick) {
            this.onClick = () => { }
        }
        this.renderer = renderer
        this.title = title
    }
    onClick: () => void
    renderer: () => any
    title: string = ''
}
