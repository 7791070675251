import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { ApplicationState } from '../store'
import { NotificationModel, NotificationType } from '../Notifications/NotificationModel'
import * as NotificationsStore from '../store/Notifications'

const Wrapper = styled.div`
    position: fixed;
    bottom: 10px;
    right: 10px;
    max-width: 50%;
    z-index: 100;
    max-height: 100%;
    background-color: white;
`
const iconClasses = ['fa-info-circle', 'fa-warning', 'fa-ban', 'fa-check']
const colors = ['#2B388F', '#F6921E', 'red', '#33B92D']
const NotificationItem = styled.div<{ type: NotificationType }>`
    border: ${(props) => '3px solid ' + colors[props.type]};
    padding: 15px;
`
const Icon = styled.i<{ type: NotificationType }>`
    color: ${(p) => colors[p.type]};
    font-size: 24px;
`
const Close = styled.i`
    float: right;
    font-size: 24px;
`

export const NotificationsWrapper = () => {
    const dispatch = useDispatch()
    const notifications = useSelector((state: ApplicationState) => state.notifications.notifications)
    const onClose = (n: NotificationModel) => {
        dispatch(NotificationsStore.actionCreators.remove(n))
    }
    return (
        <Wrapper>
            {notifications.map((n) => (
                <NotificationItem type={n.type} key={n.guid}>
                    <div>
                        <Icon type={n.type} className={`fa ${iconClasses[n.type]}`} />
                        <Close className="fa fa-times" onClick={() => onClose(n)} />
                    </div>
                    {n.message}
                </NotificationItem>
            ))}
        </Wrapper>
    )
}
