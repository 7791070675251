import { Action, Reducer } from 'redux'
import { NotificationModel } from '../Notifications/NotificationModel'

export interface State {
    notifications: NotificationModel[]
}
const NOTIFICATION_ADD = 'A_NOTIFICATION_ADD'
interface NotificationAddAction {
    type: 'A_NOTIFICATION_ADD'
    notification: NotificationModel
}
const NOTIFICATION_REMOVE = 'A_NOTIFICATION_REMOVE'
interface NotificationRemoveAction {
    type: 'A_NOTIFICATION_REMOVE'
    notification: NotificationModel
}

export type KnownAction = NotificationAddAction | NotificationRemoveAction

export const actionCreators = {
    add: (notification: NotificationModel) => <NotificationAddAction>{ type: NOTIFICATION_ADD, notification },
    remove: (notification: NotificationModel) => <NotificationRemoveAction>{ type: NOTIFICATION_REMOVE, notification },
}
const unloadedState: State = { notifications: [] as NotificationModel[] }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as KnownAction
    switch (action.type) {
        case NOTIFICATION_ADD:
            if (state.notifications.some(x => x.title == action.notification.title)) {
                return state
            }
            return {
                notifications: state.notifications.concat(action.notification),
            }
        case NOTIFICATION_REMOVE:
            return {
                notifications: state.notifications.filter((x) => x.guid != action.notification.guid),
            }
        default:
            return state
    }
}
