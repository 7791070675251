import * as React from 'react'
import { BaseFormFieldProps, FormControllerProps } from '../BaseProps'
import { FieldFooter } from '../FieldFooter'
import { FormFieldLabel } from './FormFieldLabel'
import { FormFieldWrapper } from './FormFieldWrapper'
import { NumberInput, TextInput } from '../Inputs'
export type NumberFormFieldProps = BaseFormFieldProps & {
    min?: number
    max?: number
    step?: number
}
export const EmptyFormField = (props: { height: number }) => <div style={{ height: `${props.height}px` }}></div>
export const NumberFormField = (props: NumberFormFieldProps) => (
    <FormFieldWrapper {...props}>
        {props.label && <FormFieldLabel {...props} />}
        <NumberInput {...props} />
        <FieldFooter error={props.error} errorDescription={props.errorDescription} />
    </FormFieldWrapper>
)

// export type NumberFormFieldPropsController = NumberFormFieldProps & FormControllerProps
// export const NumberFormFieldController = (props: NumberFormFieldPropsController) => (
//     <Controller
//         rules={props.rules}
//         control={props.control}
//         name={props.name || ''}
//         render={({ field }) => (
//             <NumberFormField
//                 {...props}
//                 value={field.value}
//                 onChange={(e) => {
//                     const value = parseFloat(e.target.value) || 0
//                     field.onChange(value)
//                     if (props.onChange) {
//                         props.onChange(value)
//                     }
//                 }}
//             />
//         )}
//     />
// )
