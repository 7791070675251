import * as React from 'react'
interface CheckBoxProps {
    isCheckedFunction?: () => boolean
    isChecked: boolean
    onClick: () => void
    styles?: React.CSSProperties
}
const CheckBox = (props: CheckBoxProps) => {
    const onClick = () => {
        props.onClick()
    }
    const { isCheckedFunction, isChecked, styles } = props
    return (
        <div className="datatable-checkbox-wrapper" onClick={onClick} style={styles as any}>
            {(isCheckedFunction ? isCheckedFunction() : isChecked) && (
                <i style={{ display: 'block' }} className="fa fa-check"></i>
            )}
        </div>
    )
}
export default CheckBox
