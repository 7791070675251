import * as React from 'react'
import './loading.scss'
export const LoadingSpinner = () => (
    <div className="loading-wrapper">
        <div className="blobs">
            <div className="blob-center"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"></div>
            <div className="blob"> </div>
        </div>
    </div>
)
