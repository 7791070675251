interface IndexedItem {
    index: number
}
export function indexUp<T extends IndexedItem>(array: T[], index: number): T[] {
    if (index <= 1) return array
    let newArray = array.map(x => x.index == index
        ? Object.assign({ ...x, index: x.index - 1 })
        : x.index == index - 1
            ? Object.assign({ ...x, index: x.index + 1 })
            : x
    );
    newArray = newArray.sort((a, b) => a.index > b.index ? 1 : -1)
    return newArray
}
export function indexDown<T extends IndexedItem>(array: T[], index: number): T[] {
    if (index > array.length) return array
    let newArray = array.map(x => x.index == index
        ? Object.assign({ ...x, index: x.index + 1 })
        : x.index == index + 1
            ? Object.assign({ ...x, index: x.index - 1 })
            : x
    )
    newArray = newArray.sort((a, b) => a.index > b.index ? 1 : -1)
    return newArray
}