import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { MediaDisplay } from './MediaDisplay'
import { MediaUploader } from './MediaUploader'
import * as MediaStore from '../store/Media'
import { Photo } from '../models/Photo'
import { ApplicationState } from '../store'
import { FileUploading } from '../serviceModels/File'
import { Popup } from '../Popup/Popup'
import { Pagination } from '../Pagination'
import * as Buttons from '../Buttons'
interface Props {
    selected: Photo[]
    onSelectPhotos?: (photos: Photo[]) => void
    onClose: () => void
}
export const MediaManager = (props: Props) => {
    const dispatch = useDispatch()
    const Localization = useSelector((state: ApplicationState) => state.localization.locale)
    const photos = useSelector((state: ApplicationState) => state.photos.photos)
    const [selectedPhotos, setSelectedPhotos] = React.useState([] as Photo[])
    const onSelectPhoto = (photo: Photo) => {
        setSelectedPhotos(selectedPhotos.concat([photo]))
    }
    const onUnselectPhoto = (photo: Photo) => {
        console.log('Unselect')
        setSelectedPhotos(selectedPhotos.filter((x) => x.id != photo.id))
    }
    const onAddImages = () => {
        if (props.onSelectPhotos) props.onSelectPhotos(selectedPhotos)
        props.onClose()
    }
    React.useEffect(() => {
        if (!photos) {
            dispatch(MediaStore.actionCreators.requestPhotos(1))
        }
    }, [])
    const onPageChanged = (page: number) => {
        dispatch(MediaStore.actionCreators.requestPhotos(page))
    }

    const onFilesSelected = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = [] as FileUploading[]
        if (e.currentTarget.files) {
            for (let i = 0; i < e.currentTarget.files.length; i++) {
                const f = e.currentTarget.files[i]
                if (!files.some((x) => x.file.name == f.name)) files.push(new FileUploading(f))
            }
            files
                .filter((x) => !x.data)
                .forEach((f) => {
                    let reader = new FileReader()
                    reader.onloadend = () => {
                        const file = Object.assign(new FileUploading(f.file), { data: reader.result as string })
                        dispatch(MediaStore.actionCreators.upload(file))
                    }
                    reader.readAsDataURL(f.file)
                })
        } else {
        }
    }
    return (
        <Popup onClose={props.onClose}>
            <div className="field-wrap">
                <div className={`drop-field-wrap${props.onSelectPhotos ? ' half' : ''}`}>
                    <input
                        onChange={onFilesSelected}
                        type="file"
                        name="file-drop-upload2"
                        id="file-drop-upload2"
                        accept="image/*"
                        className="drop-file-upload"
                        multiple
                    />
                    <div className="drop-field-caption">{Localization.getString('Drag & Drop Media Here')}</div>
                    <span className="drop-field-or">{Localization.getString('or')}</span>
                    <label htmlFor="file-drop-upload2" className="drop-field-button">
                        {Localization.getString('Select Files')}
                    </label>
                </div>
                <div className={`drop-field-wrap preview${props.onSelectPhotos ? ' half' : ''}`}>
                    <div className="drop-field-uploaded-wrap">
                        <ul className="drop-field-uploaded media-manager-preview">
                            {selectedPhotos.map((x) => (
                                <MediaDisplay key={x.id} photo={x} hideEdit onDelete={onUnselectPhoto} />
                            ))}
                        </ul>
                        {selectedPhotos.length > 0 && [
                            <Buttons.ButtonSmallGreen onClick={onAddImages}>
                                {Localization.getString('Add images')}
                            </Buttons.ButtonSmallGreen>,
                            <br />,
                        ]}
                    </div>
                </div>
                <div className="drop-field-wrap">
                    <div className="drop-field-uploaded-wrap">
                        {photos && photos.pagingInfo.totalPages > 0 && (
                            <Pagination onPageChange={onPageChanged} pagination={photos.pagingInfo} />
                        )}
                        <ul className="drop-field-uploaded">
                            <MediaUploader />
                            {photos ? (
                                photos.entities.map((p) => {
                                    const isSelected = selectedPhotos.some((x) => x.id == p.id)
                                    return (
                                        <MediaDisplay
                                            type="Select"
                                            key={p.id}
                                            onSelect={
                                                isSelected || props.selected.some((x) => x.id == p.id)
                                                    ? undefined
                                                    : onSelectPhoto
                                            }
                                            isSelected={isSelected}
                                            photo={p}
                                        />
                                    )
                                })
                            ) : (
                                <p>{Localization.getString('Loading')}...</p>
                            )}
                        </ul>
                    </div>
                </div>
            </div>
            {/* <button onClick={props.onClose} className="button has-glow sticky">
                {Localization.getString('Close')}
            </button> */}
        </Popup>
    )
}
