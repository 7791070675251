import { Action, Reducer } from 'redux'

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface State {
    loading: number
}

const INCREMENT_LOADING = 'A_INCREMENT_LOADING'
interface IncrementLoadingAction {
    type: 'A_INCREMENT_LOADING'
}
const DECREMENT_LOADING = 'A_DECREMENT_LOADING'
interface DecrementLoadingAction {
    type: 'A_DECREMENT_LOADING'
}

export type KnownAction = IncrementLoadingAction | DecrementLoadingAction

export const actionCreators = {
    incrementLoading: () => <IncrementLoadingAction>{ type: INCREMENT_LOADING },
    decrementLoading: () => <DecrementLoadingAction>{ type: DECREMENT_LOADING },
}

const unloadedState: State = { loading: 0 }

export const reducer: Reducer<State> = (state: State | undefined, incomingAction: Action): State => {
    if (state === undefined) {
        return unloadedState
    }
    const action = incomingAction as KnownAction
    switch (action.type) {
        case INCREMENT_LOADING:
            return {
                loading: state.loading + 1,
            }
        case DECREMENT_LOADING:
            return {
                loading: state.loading - 1,
            }
        default:
            return state
    }
}
