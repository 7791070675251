import * as React from 'react'
import styled from 'styled-components'
import { guid } from './services/guid'
interface CheckBoxProps {
    label?: string
    name: string
    isCheckedFunction?: () => boolean
    isChecked: boolean
    onClick: () => void
    styles?: React.CSSProperties
}

export const CheckBox = (props: CheckBoxProps) => {
    const [id, setId] = React.useState(guid())
    const onClick = () => {
        props.onClick()
    }
    const isChecked = props.isCheckedFunction ? props.isCheckedFunction() : props.isChecked
    return (
        <div onClick={onClick} className={`custom-checkbox-wrap is-default-style${isChecked ? ' is-checked' : ''}`}>
            <input
                type="checkbox"
                id={id}
                name={props.name}
                checked={isChecked}
                className="custom-checkbox"
                data-label={props.label}
            />
            {props.label && <span>{props.label}</span>}
        </div>
        // <StyledCheckBox onClick={this.onClick} style={styles as any}>
        //     {(isCheckedFunction ? isCheckedFunction() : isChecked) ? (
        //         <i style={{ display: 'block' }} className="fa fa-check"></i>
        //     ) : (
        //         <i style={{ display: 'block', opacity: 0 }} className="fa fa-check" />
        //     )}
        // </StyledCheckBox>
    )
}
