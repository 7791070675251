import * as AuthUtils from '../Utils/authUtils'
import { FileUploading } from './../serviceModels/File'
import { Photo } from '../models/Photo'
interface UploaderHeader {
    key: string
    value: string
}
interface UploaderWithProgressParams {
    file: FileUploading
    url: string
    onProgress?: (progress: number) => void
    onUploaded?: () => void
    onError?: Function
    onFinished?: (photo: Photo) => void
    onAuthError?: Function
    headers?: UploaderHeader[]
    guid: string
}
export default class UploaderWithProgress {
    file: FileUploading
    progress: number
    headers?: UploaderHeader[]
    url: string
    reader: FileReader
    xhr: XMLHttpRequest
    uploaded: boolean
    successful: boolean
    error: any
    onProgress?: Function
    onUploaded?: Function
    onFinished?: Function
    onError?: Function
    onAuthError?: Function
    token: string
    guid: string

    constructor(params: UploaderWithProgressParams) {
        this.token = AuthUtils.getToken() || ''
        this.file = params.file
        this.url = params.url
        this.headers = params.headers
        this.reader = new FileReader()
        this.xhr = new XMLHttpRequest()
        this.progress = 0
        this.uploaded = false
        this.successful = false
        this.onProgress = params.onProgress
        this.onUploaded = params.onUploaded
        this.onError = params.onError
        this.onFinished = params.onFinished
        this.onAuthError = params.onAuthError
        this.guid = params.guid
        const self = this
        this.xhr.upload.addEventListener(
            'progress',
            (e) => {
                if (e.lengthComputable) {
                    this.progress = (e.loaded * 100) / e.total
                    if (this.onProgress) {
                        this.onProgress(Math.round(this.progress))
                    }
                }
            },
            false,
        )
        this.xhr.upload.addEventListener(
            'load',
            (e) => {
                this.progress = 0
                this.uploaded = true
                if (this.onUploaded) {
                    this.onUploaded()
                }
            },
            false,
        )
        this.xhr.upload.addEventListener('error', (e) => {
            this.progress = 0
            this.error = {
                code: 1,
                text: (e as any).error,
            }
            if (this.onError) {
                this.onError(this.error)
            }
        })

        this.xhr.onreadystatechange = function () {
            if (this.readyState == 4) {
                if (this.status == 200) {
                    if (!self.uploaded) {
                        if (self.onFinished) {
                            self.onFinished(JSON.parse(this.response))
                        }
                    } else {
                        self.successful = true
                        if (self.onFinished) {
                            self.onFinished(JSON.parse(this.response))
                        }
                    }
                } else {
                    if (this.status == 401) {
                        self.error = {
                            code: this.status,
                            text: `Ошибка авторизации. Повторите вход в систему`,
                        }
                        if (self.onError) {
                            self.onError(self.error)
                        }
                    } else {
                        self.error = {
                            code: this.status,
                            text: `Ошибка ${this.status}`,
                        }
                        if (self.onError) {
                            self.onError(self.error)
                        }
                    }
                }
            }
        }
        this.upload()
    }
    upload() {
        this.xhr.open('POST', this.url)
        this.xhr.setRequestHeader('Cache-Control', 'no-cache')
        this.xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')
        this.xhr.setRequestHeader('Content-Type', 'application/json')
        this.xhr.setRequestHeader('Authorization', 'Bearer ' + this.token)
        if (this.headers) {
            this.headers.forEach((header) => {
                this.xhr.setRequestHeader(header.key, header.value)
            })
        }
        const fileUploadRequest = { guid: this.file.guid, data: this.file.data }
        const fileUploadRequestJSON = JSON.stringify(fileUploadRequest)
        this.xhr.send(fileUploadRequestJSON)
    }
}
